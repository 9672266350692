import React from "react";
function InfoBtn(props) {

    return (
        <span className="tooltiptally">
            <img src="https://dashboard.tally-app.com/immaginiComuni/iconai.svg" className="tooltip-i"></img>
            <span className="tooltiptext-div"><span className="tooltiptext">{props.info}</span></span>
        </span>
    );
}
export default InfoBtn;
