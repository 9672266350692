import React from "react";

function Loading(props) {
    function caricaDashboard(){
        let port="";
        (window.location.port || window.location.port!=80 || window.location.port !== 443)?port = ":"+window.location.port:port="";
        window.location.replace(window.location.protocol+"//"+window.location.hostname+port+"/login");    
    }    
    if (props.errorLogin) {
        caricaDashboard();
    }
    else {
        return (
            <div className="col align-items-center justify-content-center" style={{ marginTop: "15%", textAlign: "center",minHeight:"500px" }}>
                <img style={{ width: "150px", margin: "0 auto", textAlign: "center", }} src="https://dashboard.tally-app.com/assets/icon/91.gif"></img>
                {props.text && <div className="testoCitazioneLoader" dangerouslySetInnerHTML={props.text} ></div>}
                <p className="sottotesto-loader">{props.sottoTesto && props.sottoTesto}</p>
            </div>
        );
    }
}

export default Loading;