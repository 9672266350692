import React from "react";

function ListChoosePlan(props) {
    return (
        <div className="row scegli-piano-list">
        <div className="col-1 ">
            <div className="radio-list"></div>
        </div>
        <div className="col-10">
            {props.content}
        </div>
    </div>
    );
}

export default ListChoosePlan;