import React from "react";
import ListChoosePlan from "../Component/listChoosePlan.js";

function ChoosePlan(props) {

    return (
        <div className="row choose-plan-row" >
            <div className="col-12 col-sm-3">
                <br></br>
                <p className="scegli-piano-title" style={{ fontWeight: "100", fontSize: "1.8em", lineHeight: "1em" }}>Prova Tally per 15 Giorni gratis!</p>
                <p className="descrizione-scegli-piano">Seleziona il piano che più si adatta alle tue esigenze.<br></br><br></br>
                Durante il <b>periodo di prova non ti verrà addebiato nessun costo.</b> <br/><br/>
                 Dopo i <b>15 giorni di prova gratuita</b> ti verrà addebitata la quota prevista.<br></br><br></br>
                 Potrai comunque <b>disdire l'abbonamento</b> online in <b>qualsiasi momento</b>.</p>
            </div>
            <div className="col-12 col-sm-3 scegli-piano-box" style={{ marginLeft: "4%" }}>
                <div className="scegli-piano-header-box">
                    <span className="scegli-piano-header-box-title">Base</span>
                    <p style={{ paddingBottom: "15px", color: "white", fontWeight: "100" }}>15 giorni gratis</p>
                </div>
                <div className="scegli-piano-body-box" style={{ marginTop: "15px" }}>
                    <span className="scegli-piano-body-box-title"><span className="scegli-piano-body-box-title-1" >29.90<span className="euro-scegli-piano">€</span></span><span className="scegli-piano-body-box-title-2">al mese</span></span>
                </div>
                <div className="scegli-piano-body-content">
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">1 Progetto </span> (Web App)<br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">1 Template grafico </span> per categoria <br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">QR Code multipli</span> associato alla Web App <br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">Link condivisibile </span> <br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span>Monitoraggio<span className="scegli-piano-bold"> dati analitici </span><br></br></span>}></ListChoosePlan>
                </div>
                <div className="container-scegli-piano-button"><button onClick={() => { props.cambiaStato(1, 0) }} value={1} name={0} className="button-scegli-piano">Scegli il Base</button></div>
            </div>
            <div className="col-12 col-sm-3 scegli-piano-box-2" >
                <div className="scegli-piano-header-box">
                    <span className="scegli-piano-header-box-title">Pro</span>
                    <p style={{ paddingBottom: "15px", color: "white", fontWeight: "100" }}>15 giorni gratis</p>
                </div>
                <div className="scegli-piano-body-box" style={{ marginTop: "15px" }}>
                    <span className="scegli-piano-body-box-title"><span className="scegli-piano-body-box-title-1">49.90<span className="euro-scegli-piano">€</span></span><span className="scegli-piano-body-box-title-2">al mese</span></span>
                </div>
                <div className="scegli-piano-body-content">

                    <ListChoosePlan content={<span><span className="scegli-piano-bold">2 Progetti </span> (Web App)<br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">2 Template grafici </span> per categoria <br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">QR Code multipli</span> associato alla Web App <br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">Link condivisibile </span> <br></br></span>}></ListChoosePlan>

                    <ListChoosePlan content={<span>Monitoraggio<span className="scegli-piano-bold"> dati analitici </span><br></br></span>}></ListChoosePlan>
                </div>
                <div className="container-scegli-piano-button"><button onClick={() => { props.cambiaStato(1, 1) }} className="button-scegli-piano">Scegli il Pro</button></div>
            </div>
            <div style={{ display: "none" }} className="col-12 col-sm-3 scegli-piano-box" >
                <div className="scegli-piano-header-box">
                    <span className="scegli-piano-header-box-title">For life</span>
                </div>
                <div className="scegli-piano-body-box">
                    <span className="scegli-piano-body-box-title"><span className="scegli-piano-body-box-title-1" > <span style={{ textDecoration: "line-through", fontSize: "0.3em" }}>699€</span> 150<span className="euro-scegli-piano">€</span></span><span className="scegli-piano-body-box-title-2">una tantum</span></span>
                </div>
                <div className="scegli-piano-body-content">
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">1 Progetto </span> (Web App)<br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">1 Template grafico </span> per categoria <br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">QR Code multipli</span> associato alla Web App <br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span><span className="scegli-piano-bold">Link condivisibile </span> <br></br></span>}></ListChoosePlan>
                    <ListChoosePlan content={<span>Monitoraggio<span className="scegli-piano-bold"> dati analitici </span><br></br></span>}></ListChoosePlan>
                </div>
                <br></br>
                <div className="container-scegli-piano-button"><button onClick={() => { props.cambiaStato(1, 2) }} value={1} name={0} className="button-scegli-piano">Scegli For Life</button></div>
            </div>
        </div>
    )
}

export default ChoosePlan;