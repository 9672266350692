import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyDpRdrIp-NkKqyKD-Pb98ge3F4Mh9wV5J4",
    authDomain: "tally-257615.firebaseapp.com",
    databaseURL: "https://tally-257615.firebaseio.com",
    projectId: "tally-257615",
    storageBucket: "tally-257615.appspot.com",
    messagingSenderId: "542934077051",
 };
 app.initializeApp(config);
 export default app;