import React, { useState } from "react";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
function NavBarRight(props) {
    let [support, setSupport] = useState("https://dashboard.tally-app.com/immaginiComuni/Tutorial+Shortcut.svg");
    function hoverSupport() {
        setSupport("https://dashboard.tally-app.com/assets/navbar/video-hover.svg")
    }
    function leaveSupport() {
        setSupport("https://dashboard.tally-app.com/immaginiComuni/Tutorial+Shortcut.svg")
    }
    let [profilo, setProfilo] = useState("https://dashboard.tally-app.com/immaginiComuni/Profilo+Shortcut.svg");
    function hoverProfilo() {
        setProfilo("https://dashboard.tally-app.com/assets/navbar/profilo-hover.svg")
    }
    function leaveProfilo() {
        setProfilo("https://dashboard.tally-app.com/immaginiComuni/Profilo+Shortcut.svg")
    }

    let [guida, setGuida] = useState("https://dashboard.tally-app.com/assets/navbar/help-hover.svg");
    function hoverGuida() {
        setGuida("https://dashboard.tally-app.com/assets/navbar/help.svg")
    }
    function leaveGuida() {
        setGuida("https://dashboard.tally-app.com/assets/navbar/help-hover.svg")
    }
    let [news, setNews] = useState("https://dashboard.tally-app.com/immaginiComuni/News+Shortcut.svg");
    function hoverNews() {
        setNews("https://dashboard.tally-app.com/assets/navbar/message-hover.svg")
    }
    function leaveNews() {
        setNews("https://dashboard.tally-app.com/immaginiComuni/News+Shortcut.svg")
    }

    let [upgrade, setUpgrade] = useState("https://dashboard.tally-app.com/immaginiComuni/Upgrade+Shortcut.svg");
    function hoverUpgrade() {
        setUpgrade("https://dashboard.tally-app.com/assets/navbar/star-hover.svg")
    }
    function leaveUpgrade() {
        setUpgrade("https://dashboard.tally-app.com/immaginiComuni/Upgrade+Shortcut.svg")
    }

    return (
        <div className="nav-bar-right" style={{ zIndex: "100" }}>

            <div className="div-popover-tally">
                <Link to="/support">
                    <img onMouseEnter={hoverGuida} style={{ width: "52px", marginBottom: "0" }} onMouseLeave={leaveGuida} src={guida} />
                    <div className="popover-tally">Guida</div>
                </Link>
            </div>
            <br></br>

            <div className="div-popover-tally">
                <Link to="/order-tag">
                    <img onMouseEnter={hoverProfilo} onMouseLeave={leaveProfilo} style={{ width: "52px", marginBottom: "0" }} src={profilo} />
                    <div className="popover-tally">Profilo</div>
                </Link>
            </div>

            <br></br>
            <div className="div-popover-tally">

                <Link to="/support">
                    <img onMouseEnter={hoverSupport} style={{ width: "52px", marginBottom: "0" }} onMouseLeave={leaveSupport} src={support} />
                    <div className="popover-tally">Video tutorial</div>
                </Link>
            </div>
            <br></br>
            <div className="div-popover-tally">

                <a onMouseEnter={hoverNews} onMouseLeave={leaveNews} href="https://www.facebook.com/tallytap/?ref=br_rs"
                    rel="noopener noreferrer" target="_blank">
                    <img style={{ width: "52px", marginBottom: "0" }} src={news} /></a>
                <div className="popover-tally">News</div>
            </div>
            <br></br>
            <div className="div-popover-tally">
                <img src={upgrade} onMouseEnter={hoverUpgrade} onMouseLeave={leaveUpgrade} />
                <div className="popover-tally">Pro</div>
            </div>

        </div>
    );
}
export default NavBarRight;