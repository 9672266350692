import React, { useEffect, useState } from "react";
import common from "../Component/common.js";


export default (props) => {
    const [ordini, setOrdini] = useState([]);
    const [orderLenght, setOrderLenght] = useState(0);

    const [orderSelected, setOrderSelected] = useState(null);
    const [uniqueSelected, setUniqueSelected] = useState(null);

    useEffect(() => {
        let lenght_order = 0;
        getOrdini();
        const id = setInterval(async () => {
            let app_length = await getOrdini();
            if (lenght_order < app_length && lenght_order > 0) {
                alert("NUOVO ORDINE")
            }
            lenght_order = app_length;
        }, 1000000);
        return () => clearInterval(id);
    }, []);

    const getOrdini = async () => {
        let result = await common.eseguiPostRequestWithAuthorization(
            "/getOrders",
            {}
        );
        (result.length > 0) ? setOrdini(result) : alert("Errore nel caricamento degli ordini");
        return result.length;
    }

    const removeDuplicates = (items, piattoid) => items.reduce((acc, curr) => { return (piattoid.id === curr.id) ? acc = acc + 1 : acc; }, 0)

    const test = (prodotti) => {
        let carrelloUnico = prodotti.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
        let descriptionArray = [];
        carrelloUnico.map(piatto => {
            descriptionArray.push({
                prodotto: piatto,
                quantita: removeDuplicates(prodotti, piatto)
            });
        });
        setUniqueSelected(descriptionArray);
    }

    const changeOrderStatus = async (orderId) => {
        let result = await common.eseguiPostRequestWithAuthorization(
            "/changeOrderStatus",
            { idOrder: orderId }
        );
        if (result) { alert("Ordine cambiato"); getOrdini(); }
        else { alert("Errore nel cambiare stato ordine"); }

    }
    return <div style={{ marginLeft: "7%", marginRight: "7%" }}>
        <div class="row" style={{ marginTop: "25px" }}>
            {ordini.map(order => <div key={order.ID_ORDER} style={{ marginTop: "25px" }} className="col-12 col-sm-4 col-md-4">
                <div className="card" style={{ padding: "25px", borderRadius: "25px" }}>
                    <h3><b>{order.ID_ORDER}</b></h3>
                    <ul>
                        <li>Data Creazione: {order.DATA_ORDINE}</li>
                        <li>Nome: <b>{order.NOME}</b></li>
                        <li>Email: <b>{order.EMAIL}</b></li>
                        <li>Via: <b>{order.ADDRESS}</b></li>
                    </ul>
                    {order.CONSEGNATO == 0 ?
                        <button style={{ width: "100%" }} className="btn-tally-general" onClick={() => { changeOrderStatus(order.ID_ORDER) }}>Consegnato</button>
                        :
                        <p style={{ textAlign: "center", paddingTop: "8px" }}><b style={{ color: "green" }}>Già consegnato</b></p>
                    }
                    <button style={{ width: "100%", marginTop: "15px" }} onClick={() => { setOrderSelected(order); test(order.PRODOTTI[0]); }} data-toggle="modal" data-target="#exampleModalCenter" className="btn-tally-show-tag-grey">Info</button>
                </div>
            </div>)}
            <div className="modal fade" id="exampleModalCenter" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content" style={{ border: "1px solid rgb(255, 0, 216)" }}>
                        <div className="modal-header" style={{ border: "0" }}>
                            <img data-dismiss="modal" style={{ cursor: "pointer" }} className="close" src={"https://dashboard.tally-app.com/assets/icon/closeX.gif"} />
                        </div>
                        <div className="modal-body">
                            <div id="body-qr" style={{padding:"15px 30px 15px 30px"}}>
                                <li>Data Creazione: {orderSelected?.DATA_ORDINE}</li>
                                <li>Nome: <b>{orderSelected?.NOME}</b></li>
                                <li>Email: <b>{orderSelected?.EMAIL}</b></li>
                                <li>Via: <b>{orderSelected?.ADDRESS}</b></li>
                                <li>Id Tag: <b>{orderSelected?.ID_TAG}</b></li>
                                <li>Consegnato: <b>{orderSelected?.CONSEGNATO == 1 ? "Consegnato" :"Non consegnato"}</b></li>
                                <div className="row" style={{ marginTop: "10px" }}>
                                    {
                                        uniqueSelected?.map(prodotto => {
                                            return <div className="col-12 col-sm-3" style={{ borderRadius: "25px", }}>
                                                <div style={{ borderRadius: "25px", height: "100px", width: "100px", backgroundSize: "cover", margin: "0 auto", backgroundImage: `url("${prodotto.prodotto.immagine}")` }} />
                                                <p style={{ padding: "15px" }}><b>{prodotto.prodotto.nome}</b><br />{prodotto.prodotto.prezzo}€<br />Quantità:<b>{prodotto.quantita}</b></p>

                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}