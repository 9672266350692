import React from "react";
import PayPal from "./PayPal.js";

function PayPalPage(props) {

    return (
        <div className="row choose-plan-row" >
            <div className="col-12 col-sm-4">
                <p className="scegli-piano-title" style={{ fontWeight: "100", fontSize: "1.8em", lineHeight: "1em" }}>Prova Tally per 7 Giorni gratis!</p>
                <br></br>
                <div className="procedi-con-pagamento-text-left"><p className="descrizione-scegli-piano">Durante il periodo di <b>prova gratuita di 7 giorni</b> non ti verrà addebiato nessun costo.
                <br></br><br></br>Protrai pagare con il tuo Account PayPal o con Carta di Credito.<br></br><br></br>
                Dopo i <b>7 giorni di prova gratuita</b> il pagamento verrà addebitato mensilmente.</p></div>
            </div>
            <div className="col-12 col-sm-4 container-paypal-box">
                <PayPal checkSubscription={props.checkSubscription} pianoScelto={props.pianoScelto}></PayPal>
            </div>
        </div>
    )
}

export default PayPalPage;