/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { onSearchSubmit } from "./UnsplashSearch.js";
class CroppedTemplate extends PureComponent {
  state = {
    src: null,
    page: 1,
    Checked: true,
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 50,
      height: 50,
      compressImageQuality: 1
    }
  };

  async componentDidMount() {
    this.props.immagineAttuale ? this.setState({ Checked: true }) : this.setState({ Checked: false });
    this.setState({ immagineVecchia: this.props.immagineAttuale });
  }

  rollDown = e => {
    this.setState({ src: null, page: 1 })
    this.props.aggiornaImage(this.state.immagineVecchia, this.props.Nome, null, this.props.NomeFile, this.state.originalName, this.props.IdImmagine, this.props.nomeAttributo);
  }

  onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const nameFile = e.target.files[0].name;
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result })
      });
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ originalName: nameFile });

    }
  };
  selectUnsplashImage = async (imageSelected) => {
    let blobImage = await fetch(imageSelected)
      .then(function (response) {
        return response.blob()
      })
      .then(function (blob) {
        return blob;
      });
    var reader2 = new FileReader();
    reader2.readAsDataURL(blobImage);
    reader2.onloadend = async () => {
      await this.setState({ src: reader2.result });
    }
    document.getElementById("close-modal-search" + this.props.Nome + (this.props.IdImmagine != null ? this.props.IdImmagine : "")).click();
  }

  searchUnsplash = async (page) => {
    let search = this.props.vertical ? await onSearchSubmit(this.state.ricerca_unsplash, this.props.vertical) : await onSearchSubmit(this.state.ricerca_unsplash, this.props.vertical);
    this.setState({ resultUnsplash: search.hits });
  }

  onImageLoaded = image => {
    this.imageRef = image;
    this.setState({ immagineVecchia: this.props.immagineAttuale })
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.state.originalName
      );
      this.props.aggiornaImage(croppedImageUrl, this.props.Nome, this.state.blobAttuale, this.props.NomeFile, this.state.originalName, this.props.IdImmagine, this.props.nomeAttributo);
    }
  }
  openSearchModal = () => {
    let a = document.getElementById("button-open-search" + this.props.Nome + (this.props.IdImmagine != null ? this.props.IdImmagine : ""));
    a.click();
  }
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * 2;
    canvas.height = crop.height * 2;
    const ctx = canvas.getContext("2d");
    console.log(crop, scaleX, scaleY, image.naturalWidth, image.width, image.naturalHeight, image.height)
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * 2,
      crop.height * 2
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        this.setState({ blobAttuale: blob });
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/png", 1);
    });
  }
  handleClick = (e) => {
    this.inputElement.click();
  }
  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <div style={{ marginBottom: "20px", marginTop: "5px" }}>
        <div>
          <div className='container_img' style={{ backgroundColor: "#F0F0F0	" }}>
            <img className='resize_fit_center_img' src={this.props.immagineAttuale} />
          </div>

          <button type="button" style={{ margin: "0 auto", marginTop: "15px", marginBottom: "0" }} className="btn-tally-grey" data-target={"#modalCropped" + this.props.Nome + (this.props.IdImmagine != null ? this.props.IdImmagine : "")} data-toggle="modal" data-backdrop="static" data-keyboard="false">Cambia Immagine</button>

          <div className="modal modal-crop fade" id={"modalCropped" + this.props.Nome + (this.props.IdImmagine != null ? this.props.IdImmagine : "")}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-support-form ">
              <div className="modal-content modal-content-support-form modal-xl">
                <div className="modal-header">
                  <p style={{ fontWeight: "bold", marginBottom: "0" }}>Cambio Immagine</p>
                  <img style={{ cursor: "pointer" }} className="close" onClick={this.rollDown} data-dismiss="modal" src={"https://dashboard.tally-app.com/assets/icon/closeX.gif"} />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className={src ? "col-6" : "col-12"}>
                      <input ref={input => this.inputElement = input} className="btn-tally-grey" type="file" name="file1" onChange={this.onSelectFile} />
                      <p style={{ textAlign: "left" }}>Anteprima:</p>
                      <div className='container_img' style={{ backgroundColor: "#F0F0F0	" }}>
                        <img className='resize_fit_center_img' src={this.props.immagineAttuale} />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {!src && <button className="btn-tally-grey" style={{ width: "300px", margin: "0 auto", marginBottom: "0px", marginTop: "20px" }} onClick={this.handleClick}>Carica Immagine</button>}
                        <br></br>
                        {!src && <button className="button-success-tally" style={{ width: "300px", margin: "0 auto", marginBottom: "20px", marginTop: "10px" }} onClick={this.openSearchModal}>Cerca sul web</button>}
                        <br></br>
                        {src && <button className="btn-tally-grey" style={{ width: "300px", margin: "0 auto" }} onClick={this.handleClick}>Cambia Immagine</button>}
                        {!src && <button data-dismiss="modal" style={{ width: "300px", margin: "0 auto" }} onClick={this.rollDown} className="btn-tally-grey">{!src ? "Annulla" : "Annulla"}</button>}
                      </div>
                    </div>
                    {src && (
                      <div className="col-6">
                        <div style={{ margin: "0 auto", textAlign: "center" }}>
                          <p style={{ textAlign: "left" }}>Seleziona la parte d'immagine da inserire.</p>
                          <ReactCrop
                            src={src}
                            crop={crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                          /></div>
                        {src &&
                          <button data-dismiss="modal" onClick={() => this.props.UploadImage(this.props.NomeFile, this.props.Nome, (this.props.IdImmagine != null ? this.props.IdImmagine : ""), this.props.nomeAttributo)} style={{ marginBottom: "20px", marginTop: "20px" }} className="button-success-tally">
                            Salva
                          </button>}
                        {src && <button data-dismiss="modal" onClick={this.rollDown} className="btn-tally-grey">{!src ? "Annulla" : "Annulla"}</button>}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button style={{ display: "none" }} data-target={"#searchModal" + this.props.Nome + (this.props.IdImmagine != null ? this.props.IdImmagine : "")} id={"button-open-search" + this.props.Nome + (this.props.IdImmagine != null ? this.props.IdImmagine : "")} data-toggle="modal" data-backdrop="static" data-keyboard="false" />

          <div className="modal fade" id={"searchModal" + this.props.Nome + (this.props.IdImmagine != null ? this.props.IdImmagine : "")}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-support-form">
              <div className="modal-content modal-content-support-form">
                <div className="modal-header">
                  <p style={{ fontWeight: "bold", marginBottom: "0" }}>Cerca immagine web</p>
                  <img id={"close-modal-search" + this.props.Nome + (this.props.IdImmagine != null ? this.props.IdImmagine : "")} style={{ cursor: "pointer" }} className="close" data-dismiss="modal" src={"https://dashboard.tally-app.com/assets/icon/closeX.gif"} />
                </div>
                <div className="modal-body">
                  <div className="row" style={{ textAlign: "center" }}>
                    <p style={{ margin: "0 auto", fontWeight: "bold" }}>Inserisci il nome dell'immagine che vuoi cercare</p>
                    <div style={{ width: "100%" }}>
                      <input style={{ minWidth: "150px", width: "100%", maxWidth: "300px", margin: "0 auto", marginBottom: "15px" }} onChange={(e) => { this.setState({ ricerca_unsplash: e.target.value }) }} type="text" ></input>
                      <br></br>
                      <button style={{ paddingTop: "5px", width: "150px", marginLeft: "15px", height: "35px" }} onClick={this.searchUnsplash} className="btn-tally-grey">Cerca</button>
                    </div>
                    {this.state.resultUnsplash?.length > 0 && <p style={{ marginTop: "10px" }}>Scelgi l'immagine che vuoi utilizzare nel tuo template</p>}
                    {this.state.resultUnsplash?.length == 0 && <p style={{ marginTop: "10px", color: "red" }}>Non è stata trovata nessuna immagine con il termine inserito.</p>}
                    <div className="row" style={{ width: "100%", marginTop: "10px", minHeight: "500px", maxHeight: "500px", overflow: "scroll" }}>
                      {this.state.resultUnsplash?.map((image, key) => {
                        return (
                          <div key={key} onClick={() => { this.selectUnsplashImage(image.webformatURL) }} className="col-4 choose-image" style={{ height: "230px", overflow: "hidden", backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${image.webformatURL})` }} />
                        )
                      })}
                    </div>
                    <button style={{ paddingTop: "5px", width: "150px", marginLeft: "15px", height: "35px" }} onClick={this.searchUnsplash} className="btn-tally-grey">Cerca</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default CroppedTemplate;
