import React from "react";
import common from "../Component/common.js";
import Loading from "../Component/Loading.js";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

class ManageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, };
    this.checkTemplate = this.checkTemplate.bind(this);
  }
  async componentDidMount() {
    if (await this.checkTemplate()) { this.setState({ loaded: true }); }
  }
  async checkTemplate() {
    let result = await common.eseguiPostRequestWithAuthorization(
      "/getTemplate",{idTag: this.props.match.params.idTag}
    );
    if (!result) {
      this.setState({ errorLogin: true });
      return false;
    }
    if (result === null || result === undefined || !result.template || result.template.length === 0 || !result.template[0].SITO.id) {
      this.setState({ chooseTemplate: true });
      return true;
    } else {
      this.setState({ template: result.template[0] });
      return true;
    }
  }
  render() {
    if (this.state.loaded !== true) {
      return (
        <Loading errorLogin={this.state.errorLogin}></Loading>
      );
    }
    return (
      <div>
        {!this.state.chooseTemplate && (
          <Redirect to={"/create-template/" + this.props.match.params.idTag + "/" + this.state.template.SITO.id} />
        )}
        {this.state.chooseTemplate && (
          <Redirect to={"/choose-template/" + this.props.match.params.idTag} />
        )}
      </div>
    );
  }
}
export default ManageTemplate;
