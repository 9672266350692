import React from "react";
function InputTemplate(props) {
  return (
    <div style={{ marginBottom: "20px",marginTop:"15px" }}>
      <p className="title-input" style={{ marginBottom: "2px" }}>{props.titoloHeader} {props.errore && <span className="titolo-tally" style={{ color: "red" }}>*</span>}</p>
      <input
        type={props.type || "text"}
        className="input-create-template"
        placeholder={props.titolo}
        maxLength={props.maxlength}
        onChange={props.cambia}
        name={props.name}
        value={props.titolo}
        disabled={props.disabilitato || false}
      ></input>
    </div>
  );
}
export default InputTemplate;
