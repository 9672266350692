const common = {
  cambiaValoreTesto: function (stato, evento) {
    stato.setState({ [evento.target.name]: evento.target.value });
  },

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  },
  async getAzienda(stato) {
    let azienda = localStorage.getItem("azienda");
    if (azienda) {
      azienda = JSON.parse(azienda);
      stato.setState({ azienda: azienda });
      return true;
    }
    else {
      let result = await common.eseguiPostRequestWithAuthorization(
        "/checkLoginDb",
        {}
      );
      if (result === null || result === undefined || !result.azienda) {
        stato.setState({ errorLogin: true });
        return false;
      } else {
        localStorage.setItem("azienda", JSON.stringify(result.azienda[0]));
        stato.setState({ azienda: result.azienda[0] });
        return true;
      }
    }
  },

  async checkTagAzienda(idTag, stato) {
    let result = await common.eseguiPostRequestWithAuthorization(
      "/checkLoginTag",
      { idTag: idTag }
    );
    if (result === null || result === undefined || !result.azienda) {
      stato.setState({ errorLogin: true });
      return false;
    } else {
      stato.setState({ azienda: result.azienda[0] });
      return true;
    }
  },

  creaMatrice(rows) {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr[i] = [];
    }
    return arr;
  },
  apriLinkPopUp(url) {
    window.open(url, 'titolo', 'width=700, height=300, resizable, status, scrollbars=1, location')
  },
  eseguiPostRequestWithAuthorization: async function (endpoint, params) {
    const results = await fetch(process.env.REACT_APP_BACKEND + endpoint, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token"),
        credentials: "include"
      },
      body: JSON.stringify(params)
    })
      .then(res => res.json())
      .then(
        result => {
          return result;
        },
        error => {
          //alert(error);
          return false;
        }
      );
    return results;
  },
  eseguiPostRequestNoAuthorization: async function (endpoint, params) {
    const results =
      await fetch(process.env.REACT_APP_BACKEND + endpoint, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(params)
      })
        .then(res => res.json())
        .then(
          result => {
            return result;
          },
          error => {
            alert(error);
            return error;
          }
        );
    return results;
  },
  eseguiGetRequestAuthorization: async function (endpoint, params) {
    let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    let url = process.env.REACT_APP_BACKEND + endpoint + query;
    const results = await fetch(url)
      .then(res => res.json())
      .then(
        result => {
          return result;
        },
        error => {
          alert(error);
          return error;
        }
      );
    return results;
  }
};


export async function UploadImageTemplate(nomeFile, nomeTemplate, stato) {
  let file = stato.state[nomeFile];
  const data = new FormData();
  data.append("file", file, file.name);
  fetch(process.env.REACT_APP_BACKEND + "/upload", {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      credentials: "include"
    },
    body: data
  })
    .then(res => res.json())
    .then(
      result => {
        if (result && result.imgpath) {
          stato.setState({
            template: {
              ...stato.state.template,
              [nomeTemplate]: result.imgpath + "?" + new Date().getTime()
            }
          });
        }
      },
      error => {
        alert(error);
      }
    );
};

export function aggiornaImage(src, name, file, nomefile, nomeoriginale, stato) {
  stato.setState({ template: { ...stato.state.template, [name]: src } });
  if (file) {
    file.name = nomeoriginale;
    stato.setState({ [nomefile]: file });
  }
}

export async function uploadImageCreateTemplate(file) {
  const data = new FormData();
  data.append("file", file, file.name);
  return fetch(process.env.REACT_APP_BACKEND + "/upload", {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
      credentials: "include"
    },
    body: data
  })
    .then(res => res.json())
    .then(
      result => {
        return result;
      },
      error => {
        alert(error);
      }
    );
}

export default common;
export function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}
