import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import common from "../Component/common.js";
function Paypal(props) {
  //Vecchi Piani = const piani = ['P-30878930U8477014WL3YGWGA', 'P-4U804051FM7021723L3YGXSI'];
  const piani = ['P-2N0360794V569505JL3DKACI', 'P-39Y08622PA164512LL3FYRNY'];
  return (
    <div>
      <p className="scegli-piano-title" style={{ fontWeight: "300", fontSize: "18px" }}>Paga con il tuo account PayPal o con una Carta di credito/debito!</p>
      <PayPalButton
        options={{ vault: true }}
        style={{ shape: 'pill', color: 'white', layout: 'vertical', label: 'subscribe', }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: piani[props.pianoScelto]
          });
        }}
        onApprove={(data, actions) => {
          return actions.subscription.get().then(async function (details) {
            let result = await common.eseguiPostRequestWithAuthorization("/insertSubscription", { subscriptionID: details.id, data_scadenza_contratto: details.billing_info.next_billing_time });
            props.checkSubscription(result.status);
          });
        }}
      />
    </div>
  );
}

export default Paypal;
