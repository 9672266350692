import React from "react";
import "../Style/Login.css";
import common from "../Component/common.js";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
import Loading from "../Component/Loading.js";
import firebase from "../Config/firebase.js";
import {
  Line,
  HorizontalBar,
  Doughnut,
  Bar,
  Chart as ChartJS
} from "react-chartjs-2";
import { Chart } from "react-google-charts";
import TimeComponent from "../Component/timeComponent.js";

require("../Component/RoundedBars.js");
const db = firebase.firestore();

class Tag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      errorTag: false,
      tag: [],
      errorLogin: false,
      tagView: [],
      viewGraficoViews: false,
      optionsBar: {
        legend: { display: false },
        maintainAspectRatio: false,
        ticks: {
          min: 0
        },
        scales: {

          xAxes: [
            {
              barPercentage: 0.8,
              gridLines: {
                display: false
              },
              padding: 1
            },

          ],
          yAxes: [
            {
              barPercentage: 0.3,
              gridLines: {
                display: true
              },
              ticks: {
                maxTicksLimit: 3
              },
              padding: 1
            }
          ]
        }
      },
      optionsBarLeng: {
        legend: { display: false },
        maintainAspectRatio: false,
        ticks: {
          min: 0
        },
        scales: {

          xAxes: [
            {
              display: false,
              barPercentage: 0.8,
              gridLines: {
                display: false
              },
              padding: 1
            },

          ],
          yAxes: [
            {
              barPercentage: 0.3,
              gridLines: {
                display: false
              },
              legend: { display: false },
              padding: 1
            }
          ]
        }
      },
      viewTotaliData: {}
    };
    this.checkTag = this.checkTag.bind(this);
    this.getViewFromTag = this.getViewFromTag.bind(this);
    this.getGeoFromTag = this.getGeoFromTag.bind(this);
    this.getLingueFromTag = this.getLingueFromTag.bind(this);
    this.getUtentiFromTag = this.getUtentiFromTag.bind(this);
  }
  async componentDidMount() {
    document.title = "Dati - Tally Dashboard";
    let check = await this.checkTag();
    if (!check) {
      return;
    }
    let dati = await this.getDati();
    if (!dati) {
      this.setState({ dati: [] });
    }
  }
  //Calcolo per grafici
  getGeoFromTag(posizoneGeografica) {
    let v = [["Stato", "Views"]];
    Object.keys(posizoneGeografica).forEach(function (key) {
      v.push([key, posizoneGeografica[key]]);
    });
    this.setState({ geoData: v });
  }
  getdevicesFromTag(totalDevices) {
    let v = [0, 0, 0];
    Object.keys(totalDevices).forEach(key => {
      if (key === "Android") {
        v[0] = totalDevices[key];
      }
      if (key === "iOS") {
        v[1] = totalDevices[key];
      }
      if (key === "Other") {
        v[2] = totalDevices[key];
      }
    });
    let tot = v[0] + v[1] + v[2];
    let a = [];
    a[0] = (v[0] / tot) * 100;
    a[1] = (v[1] / tot) * 100;
    a[2] = (v[2] / tot) * 100;
    this.setState({ percentDevice: a });
    let testData = {
      labels: ["Android", "iOS", "Other"],
      datasets: [
        {
          data: v,
          backgroundColor: ["#6FB2FF", "#D396FF", "#FF00D8"],
          hoverBackgroundColor: ["#6FB2FF", "#D396FF", "#FF00D8"]
        }
      ]
    };
    this.setState({ testData2: testData });
  }
  getLingueFromTag(totaleLingua) {
    let v = [0, 0, 0, 0, 0];
    let labelsapp = ["Ita", "En", "Spa", "Fra", "De"];
    Object.keys(totaleLingua).forEach(key => {
      if (key.toString().toUpperCase().startsWith("IT")) {
        v[0] = v[0] + totaleLingua[key];
        labelsapp[0] = "Ita";
      }
      if (key.toString().toUpperCase().startsWith("EN")) {
        v[1] = v[1] + totaleLingua[key];
        labelsapp[1] = "En";
      }
      if (key.toString().toUpperCase().startsWith("ES")) {
        v[2] = v[2] + totaleLingua[key];
        labelsapp[2] = "Spa";
      }
      if (key.toString().toUpperCase().startsWith("FR")) {
        v[3] = v[3] + totaleLingua[key];
        labelsapp[3] = "Fra";
      }
      if (key.toString().toUpperCase().startsWith("DE")) {
        v[4] = v[4] + totaleLingua[key];
        labelsapp[4] = "De";
      }
    });
    let HorizontalBar = {
      labels: labelsapp,
      datasets: [
        {
          label: "Views",
          backgroundColor: "#6FB2FF",
          borderColor: "#6FB2FF",
          borderWidth: 1,
          hoverBackgroundColor: "#6FB2FF",
          hoverBorderColor: "#6FB2FF",
          data: v
        }
      ]
    };
    this.setState({ barHorizontal: HorizontalBar });
  }
  getUtentiFromTag(utentiTotali) {
    let anno = new Date().getFullYear();
    let nomi_mesi = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"];
    let v = [];
    for (let i = 0; i < 12; i++) {
      if (utentiTotali[anno] && utentiTotali[anno][nomi_mesi[i]]) {
        v[i] = utentiTotali[anno][nomi_mesi[i]];
      }
      else {
        v[i] = 0;
      }
    }
    let barData = {
      labels: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic"
      ],
      datasets: [
        {
          label: "Utenti",
          backgroundColor: "#D396FF",
          borderColor: "#D396FF",
          borderWidth: 1,
          hoverBackgroundColor: "#D396FF",
          hoverBorderColor: "#D396FF",
          data: v
        }
      ]
    };
    this.setState({ barData: barData });
  }

  getDataOraFromTag(giornoOra) {
    let giorniArray = [];
    Object.keys(giornoOra).forEach(key => {
      if (key === "Lun") {
        giorniArray[0] = giornoOra[key];
      }
      if (key === "Mar") {
        giorniArray[1] = giornoOra[key];
      }
      if (key === "Mer") {
        giorniArray[2] = giornoOra[key];
      }
      if (key === "Gio") {
        giorniArray[3] = giornoOra[key];
      }
      if (key === "Ven") {
        giorniArray[4] = giornoOra[key];
      }
      if (key === "Sab") {
        giorniArray[5] = giornoOra[key];
      }
      if (key === "Dom") {
        giorniArray[6] = giornoOra[key];
      }
    });
  }

  getViewFromTag(viewsTotali) {
    let anno = new Date().getFullYear();
    let nomi_mesi = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"];
    let v = [];
    for (let i = 0; i < 12; i++) {
      if (viewsTotali[anno] && viewsTotali[anno][nomi_mesi[i]]) {
        v[i] = viewsTotali[anno][nomi_mesi[i]];
      }
      else {
        v[i] = 0;
      }
    }
    let app = {
      datasets: [
        {
          label: "View",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "#fff",
          borderColor: "#FF00D8",
          borderCapStyle: "butt",
          borderDash: [],
          borderWidth: "1",
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "#FF00D8",
          pointBackgroundColor: "#FF00D8",
          pointBorderWidth: 7,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#FF00D8",
          pointHoverBorderColor: "#FF00D8",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          lineHeight: "13",
          data: v
        }
      ],
      labels: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic"
      ]
    };
    this.setState({ viewTotaliData: app });
  }
  calcolaViewTotali(datiViews) {
    let viewsTotali = 0;
    Object.keys(datiViews).forEach(key => {
      Object.keys(datiViews[key]).forEach(key2 => {
        viewsTotali = viewsTotali + datiViews[key][key2];
      });
    });
    return viewsTotali;
  }
  async checkTag() {
    let result = await common.eseguiPostRequestWithAuthorization("/checkTag", {
      idTag: this.props.match.params.idTag
    });
    if (!result || result === null || result === undefined || result.status === "error") {
      this.setState({ errorLogin: true });
      return false;
    } else {
      this.setState({ tag: result.tag });
    }
    let result2 = await common.eseguiPostRequestWithAuthorization("/getToken", {
      idTag: this.props.match.params.idTag
    });
    if (result2 && result2.token) {
      this.setState({ tokenFirebase: result2.token });
      return true;
    }
  }
  async getDati() {
    let result = await firebase.auth().signInWithCustomToken(this.state.tokenFirebase)
      .then(function (user_login) {
        return true;
      });
    this.getDataFromFirebase();
    return true;
  }
  async getDataFromFirebase() {
    let doc = this.props.match.params.idTag.toString();
    let citiesRef = await db.collection('datiTally').doc(doc).get();
    const dati = citiesRef.data();
    //this.getDataOraFromTag(dati.giornoOra);
    if (dati) {
      let viewsTotali = this.calcolaViewTotali(dati.totaleViews);
      this.getGeoFromTag(dati.posizioneGeografica);
      this.getViewFromTag(dati.totaleViews);
      this.getUtentiFromTag(dati.totaleUtenti)
      this.getdevicesFromTag(dati.TotaleDevices);
      this.getLingueFromTag(dati.totaleLingua);
      this.setState({ totalViews: viewsTotali });
      this.setState({ loaded: true, viewGraficoViews: true });
    }
    else{
      this.setState({errorDati:true});
      alert("Problema nel caricare i dati, è necessario visitare almeno una volta il link associato!")
    }
  }
  render() {
    if (this.state.errorTag || this.state.errorDati) {
      return (<Redirect to="/view-tag"></Redirect>);
    }
    if (this.state.loaded !== true || this.state.errorLogin) {
      return (<Loading errorLogin={this.state.errorLogin}></Loading>);
    }
    return (
      <div className="animated fadeIn fast">
        <div className="row" style={{ marginTop: "40px" }}>
        </div>
        <div>
          <div className="col-3 offset-1">
            <img className="img-btn-LinkTo-prodotti" style={{ marginLeft: "-23px" }} src="https://dashboard.tally-app.com/freccia-tally.svg"></img><Link to="/view-tag"><button className="btn-linkTo-prodotti">Prodotti</button></Link>
            <p className="titolo-pagina" style={{ marginLeft: "-23px" }}>Dati Statistici</p>
          </div>
        </div>
        <div className="row" style={{ marginTop: "40px" }}>
          <div
            className="col-4 offset-1"
          >
            <div className="row">
              <div className="col-5" style={{ paddingLeft: "25px",paddingBottom:"15px", textAlign:"center",marginTop: "5%",overflowX:"hidden" }}>
                <img
                  src={this.state.tag[0].URL_IMG_PRODOTTO}
                  style={{ width: "100%",maxHeight:"250px" }}
                ></img>
              </div>
              <div className="col-6" style={{ border: "1px solid  #707070", borderRadius: "30px", width: "292px", height: "auto", }}>
                <div style={{ margin: "5%" }}>
                  <span
                    style={{
                      marginTop: "5%",
                      fontWeight: "bold",
                      fontSize: "1.4em",
                      marginRight: "20px",
                      marginLeft: "5px",
                      lineHeight: "1em"
                    }}
                  >
                    {this.state.tag[0].NOME_PRODOTTO}
                  </span>
                  <br></br>
                  <br></br>
                  <p style={{ lineHeight: "1.2em" }}>
                    &bull; {new Date(this.state.tag[0].DATA_CREAZIONE)
                      .toLocaleDateString()
                      .replace(/\//g, ".")}
                    <br></br>&bull; {this.state.tag[0] && this.state.tag[0].STATO_GEOGRAFICO}
                    <br></br>&bull; {this.state.tag[0] && this.state.tag[0].CITTA}
                    <br></br>&bull; {this.state.tag[0].PUNTO_VENDITA}
                    <br></br>
                    {this.state.tag[0].NUMERO_TAG!=0 && <span>&bull;Tag: <span style={{ fontSize: "1em" }} className="titolo-alto">{common.numberWithCommas(this.state.tag[0].NUMERO_TAG)}</span></span>}
                  <br></br></p>
                  
                  <Link to={"/create-template/" + this.props.match.params.idTag}>
                    <button className="btn-tally-tag">Modifica</button>
                  </Link>

                </div>
              </div>

            </div>
          </div>
          <div className="col-2">
            <div style={{ width: "80%", margin: "0 auto" }}>
              <p className="bold">Panoramica</p>
              <div className="border-div-tag">
                <div className="border-div-tag-inside">
                  <p style={{ marginBottom: "5px" }}>TTR</p>
                  <p className="titolo-alto" style={{ fontSize: "1em" }}>{common.numberWithCommas(this.state.totalViews)}</p>
                </div>
              </div>

              <div className="border-div-tag">
                <div className="border-div-tag-inside">
                  <p style={{ marginBottom: "5px" }}>Visualizzazioni Totali</p>
                  <p className="titolo-alto" style={{ fontSize: "1em" }}>{common.numberWithCommas(this.state.totalViews)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 ">
            <p className="bold">Visualizzazioni Totali</p>
            <div>
              {this.state.viewGraficoViews && (
                <Line
                  data={this.state.viewTotaliData}
                  height={200}
                  width={400}
                  options={this.state.optionsBar}
                ></Line>
              )}
            </div>
          </div>
          <div className="col-5 offset-1 margin-top-tag">
            <p className="bold titolo-pagina-tags">Dove si trovano i tuoi utenti</p>
            <Chart
              width={"100%"}
              style={{ margin: "0 auto" }}
              height={"300px"}
              options={{
                colors: ["#6FB2FF", "#6FB2FF"],
                datalessRegionColor: "#D3D3D3",
                legend: "none",
                backgroundColor: {
                  fill: "#fff",
                  stroke: "#fff",
                  strokeWidth: "0"
                }
              }}
              chartType="GeoChart"
              data={this.state.geoData}
              mapsApiKey="AIzaSyCHm8PRZh10O_E9KerkYl2z7hspqh1A8KE"
            />
          </div>

          <div className="col-5 margin-top-tag">
            <p className="bold">Utenti per singolo mese </p>
            <div>
              <Bar
                data={this.state.barData}
                width={100}
                height={300}
                options={this.state.optionsBar}
              />
            </div>
          </div>
          {false && <div className="col-4 offset-1 margin-top-tag">
            <p className="bold titolo-pagina-tags">Visualizzazioni  per ora e giorno</p>
            <TimeComponent opacity={[0.5, 0, 0, 0, 0, 0, 0]} time={"22.00-24.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"20.00-22.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"18.00-20.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"16.00-18.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"14.00-16.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"12.00-14.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"10.00-12.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"8.00-10.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"6.00-8.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"4.00-6.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"2.00-4.00"}></TimeComponent>
            <TimeComponent opacity={[0, 0, 0, 0, 0, 0, 0]} time={"00-2.00"}></TimeComponent>
            <div className="row" style={{ marginTop: "5px" }}>
              <div className="col-1 " style={{ padding: "0", marginRight: "10px", textAlign: "center" }}><span style={{ width: "100%", textAlign: "left", fontSize: "0.8em" }}>Lun</span></div>
              <div className="col-1 " style={{ padding: "0", marginRight: "10px", textAlign: "center" }}><span style={{ width: "100%", textAlign: "left", fontSize: "0.8em" }}>Mar</span></div>
              <div className="col-1 " style={{ padding: "0", marginRight: "10px", textAlign: "center" }}> <span style={{ width: "100%", textAlign: "left", fontSize: "0.8em" }}>Mer</span></div>
              <div className="col-1 " style={{ padding: "0", marginRight: "10px", textAlign: "center" }}><span style={{ width: "100%", textAlign: "left", fontSize: "0.8em" }}>Gio</span></div>
              <div className="col-1 " style={{ padding: "0", marginRight: "10px", textAlign: "center" }}><span style={{ width: "100%", textAlign: "left", fontSize: "0.8em" }}>Ven</span></div>
              <div className="col-1 " style={{ padding: "0", marginRight: "10px", textAlign: "center" }}><span style={{ width: "100%", textAlign: "left", fontSize: "0.8em" }}>Sab</span></div>
              <div className="col-1 " style={{ padding: "0", marginRight: "10px", textAlign: "center" }}><span style={{ width: "100%", textAlign: "left", fontSize: "0.8em" }}>Dom</span></div>

            </div>

          </div>}
          <div className="col-3 offset-1 margin-top-tag">
            <p className="bold" >Devices</p>
            <Doughnut
              data={this.state.testData2}
              options={{ legend: { display: false } }}
              height={200}
            />
            <p></p>
            <div className="row">
              <div style={{ textAlign: "left" }} className="col-3 offset-2 ">
                <p>Android</p>
                <p style={{ color: "#6FB2FF" }}>{Number((this.state.percentDevice[0]).toFixed(1))}%</p>
              </div>
              <div style={{ textAlign: "left" }} className="col-3">
                <p>iOS</p>
                <p style={{ color: "#D396FF" }}>{Number((this.state.percentDevice[1]).toFixed(1))}%</p>
              </div>
              <div style={{ textAlign: "left" }} className="col-3">
                <p>Other</p>
                <p style={{ color: "#FF00D8" }}>{Number((this.state.percentDevice[2]).toFixed(1))}%</p>
              </div>
            </div>
          </div>
          <div className="col-2 offset-1 margin-top-tag">
            <p className="bold">Lingue</p>
            <div style={{ height: "60%" }}>
              <HorizontalBar
                height={10}
                options={this.state.optionsBarLeng}
                data={this.state.barHorizontal}
              ></HorizontalBar>
            </div>
          </div>
        </div>
        <div className="row" style={{ height: "50px", marginTop: "0px" }}>
        </div>
      </div>
    );
  }
}
export default Tag;
