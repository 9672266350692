import React from "react";
import common from "../Component/common.js";
import Loading from "../Component/Loading.js";
import PreviewTemplate from "../Component/PreviewTemplate.js";
import SceltaCategoria from "../Component/ChooseTemplate/SceltaCategoria.js";
import NavBarRight from "../Component/NavBarRight.js";
class ChooseTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      sceltaTemplate: [false, false, false, false, false, true],
      immaginiTemplate: [
        ["https://dashboard.tally-app.com/immaginiComuni/pasta.png"],
        ["https://dashboard.tally-app.com/immaginiComuni/templateVino.png"],
        ["https://dashboard.tally-app.com/immaginiComuni/birra.png"],
        ["https://dashboard.tally-app.com/immaginiComuni/integratori.png"],
        ['https://dashboard.tally-app.com/immaginiComuni/template8.png'],
        ['https://dashboard.tally-app.com/immaginiComuni/template-generico.jpg'],
        ['https://dashboard.tally-app.com/immaginiComuni/template10/apiua.png'],
        ['https://dashboard.tally-app.com/immaginiComuni/template13/co-turism.png'],
        ['https://dashboard.tally-app.com/immaginiComuni/template11/senza-foto.png'],
        ['https://dashboard.tally-app.com/immaginiComuni/template11/menu-digitale.png'],
        ['https://dashboard.tally-app.com/immaginiComuni/template-15.png']
      ],
      immaginescelta: 5
    };
    this.cambiaValore = this.cambiaValore.bind(this);
  }
  async componentDidMount() {
    document.title = "Scelta Template - Tally Dashboard";
    common.checkTagAzienda(this.props.match.params.idTag, this);
    this.setState({ loaded: true });

  }
  cambiaValore(event) {
    common.cambiaValoreTesto(this, event);
  }
  cambiaStile(numero) {
    let appoggio = this.state.sceltaTemplate;
    appoggio.fill(false);
    appoggio[numero] = true;
    this.setState({ sceltaTemplate: appoggio, immaginescelta: numero });
  }
  render() {
    if (this.state.loaded !== true || this.state.errorLogin) {
      return (
        <Loading errorLogin={this.state.errorLogin}></Loading>
      );
    }
    return (
      <React.Fragment>
        <NavBarRight />
        <div className="row" style={{ marginTop: "40px" }}>
          <div className="col-12 offset-1">
            <span style={{ fontSize: "1.2em", color: "#BEBEBE", fontWeight: "300", textDecoration: "none" }}>
              Nuovo Progetto&nbsp;&gt;&nbsp;<span style={{ fontSize: "1em" }} className="titolo-pagina"> Scegli lo stile grafico </span>&nbsp;&gt;&nbsp; Modifica i contenuti</span>
          </div>
        </div>
        <div className="row page-tally" style={{ marginTop: "5px" }}>
          {false && <div className="col-2 offset-1">
            <ul className="listaChooseTemplate">
              {true && <SceltaCategoria numero={5} scelta={this.state.sceltaTemplate} nome="Ristorazione" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {true && <SceltaCategoria numero={0} scelta={this.state.sceltaTemplate} nome="Food & Beverage" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {false && <SceltaCategoria numero={3} scelta={this.state.sceltaTemplate} nome="Bevande" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {true && <SceltaCategoria numero={4} scelta={this.state.sceltaTemplate} nome="Pharma" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {<SceltaCategoria numero={1} scelta={this.state.sceltaTemplate} nome="Vino" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {true && <SceltaCategoria numero={6} scelta={this.state.sceltaTemplate} nome="Servizi" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>}
              {/*Vida*/}
              {((this.state.azienda && this.state.azienda.EMAIL === "vidarte@virgilio.it") || (this.state.azienda && this.state.azienda.EMAIL === "jacopo@slumdesign.com")) &&
                <SceltaCategoria numero={2} scelta={this.state.sceltaTemplate} nome="Vida" cambiaStile={(e) => { this.cambiaStile(e) }}></SceltaCategoria>
              }
            </ul>
          </div>}
          <div className="col-10 offset-1">
            {this.state.sceltaTemplate[5] && (
              <div className="row row-template">
                <PreviewTemplate caratteristiche={
                  <ul>
                    <li>- Aggiungere prodotti e suddividerli per categorie.</li>
                    <li>- Aggiungere le fotografie dei prodotti.</li>
                    <li>- Aggiungere il prezzo dei prodotti.</li>
                    <li>- Aggiungere una descrizione del prodotto.</li>
                    <li>- Ricevere gli ordini sul tuo numero Whats App.</li>
                  </ul>}
                  descrizione="Questo è il modello perfetto per la consegna a domicilio, potrai creare il tuo negozio virtuale e ricevere direttamente gli ordini sul tuo numero Whats App o su quello della tua azienda."
                  titlepreview="Menù Cocktail" idTemplate="15" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[10]}></PreviewTemplate>

                <PreviewTemplate caratteristiche={
                  <ul>
                    <li>- Aggiungere prodotti e suddividerli per categorie.</li>
                    <li>- Aggiungere le fotografie dei prodotti.</li>
                    <li>- Aggiungere il prezzo dei prodotti.</li>
                    <li>- Aggiungere una descrizione del prodotto.</li>
                    <li>- Ricevere gli ordini sul tuo numero Whats App.</li>
                  </ul>}
                  descrizione="Questo è il modello perfetto per la consegna a domicilio, potrai creare il tuo negozio virtuale e ricevere direttamente gli ordini sul tuo numero Whats App o su quello della tua azienda."
                  titlepreview="Menù digitale" idTemplate="12" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[9]}></PreviewTemplate>
                <PreviewTemplate caratteristiche={
                  <ul>
                    <li>- Aggiungere prodotti e suddividerli per categorie.</li>
                    <li>- Aggiungere le fotografie dei prodotti.</li>
                    <li>- Aggiungere il prezzo dei prodotti.</li>
                    <li>- Aggiungere una descrizione del prodotto.</li>
                    <li>- Ricevere gli ordini sul tuo numero Whats App.</li>
                  </ul>}
                  descrizione="Questo è il modello perfetto per la consegna a domicilio, potrai creare il tuo negozio virtuale e ricevere direttamente gli ordini sul tuo numero Whats App o su quello della tua azienda."
                  titlepreview="Ordini " idTemplate="11" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[this.state.immaginescelta]}></PreviewTemplate>
                <PreviewTemplate caratteristiche="Caratteristiche" descrizione="
                Vi consigliamo questa grafica nel caso vogliate dare uno stile elegante, contemporaneo e qualitativo al vostro prodotto. Caratteristiche principali."
                  titlepreview="Prodotto con ricetta" idTemplate="1" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[0]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Bevanda" descrizione="Vi consigliamo questa grafica nel caso vogliate dare uno stile elegante, contemporaneo e qualitativo al vostro prodotto. Caratteristiche principali." idTemplate="7" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[2]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Rosso Deluxe" descrizione="Vi consigliamo questa grafica nel caso vogliate dare uno stile elegante, contemporaneo e qualitativo al vostro prodotto. Caratteristiche principali." idTemplate="8" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[4]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Bianco Mediterraneo" descrizione="Vi consigliamo questa grafica nel caso vogliate dare uno stile elegante, contemporaneo e qualitativo al vostro prodotto. Caratteristiche principali." idTemplate="17" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[6]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Rosso Classico" descrizione="Vi consigliamo questa grafica nel caso vogliate dare uno stile elegante, contemporaneo e qualitativo al vostro prodotto. Caratteristiche principali." idTemplate="2" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[1]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Integratore resistenza" descrizione="Vi consigliamo questa grafica nel caso vogliate dare uno stile elegante, contemporaneo e qualitativo al vostro prodotto. Caratteristiche principali." idTemplate="6" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[3]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Gruppi prodotti / servizi" descrizione="Vi consigliamo questa grafica nel caso vogliate creare una webapp adatta a raggruppare diversi servizi/prodotti che hanno bisogno di una descrizione articolata."
                  idTemplate="13" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[7]}></PreviewTemplate>
              </div>
            )}
            {/*Template Custom*/}
            {/*Template Vida*/}
            {this.state.sceltaTemplate[2] && (
              <div className="row row-template">
                <PreviewTemplate titlepreview="Template Quadro" descrizione="Template del singolo quadro Vidà" idTemplate="3" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[1]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Template Sezione" descrizione="Template delle varie sezioni Vidà" idTemplate="4" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[1]}></PreviewTemplate>
                <PreviewTemplate titlepreview="Template Introduzione" descrizione="Template introduzione Vidà" idTemplate="5" idTag={this.props.match.params.idTag} srcImg={this.state.immaginiTemplate[1]}></PreviewTemplate>
              </div>)}
          </div>
        </div>
      </React.Fragment >
    );
  }
}
export default ChooseTemplate;
