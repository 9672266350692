import React from "react";
import common from "../Component/common.js";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
import "../Style/Login.css";
import Loading from "../Component/Loading.js";
import TagView from "../Component/TagView.js";
import QRCode from "qrcode.react";
import NavBarRight from "../Component/NavBarRight.js";
import jsPDF from 'jspdf';
import { withTranslation } from 'react-i18next';

class ViewTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      tag: [],
      id_qr: 1,
      loadingQR: true,
      tagView: [],
      templateChoose: 0,
      animated: "viewTag-page animated fadeIn fast",
      offset: 0,
      link: "x",
      templatePDF: [
        { color: "black", url: "https://dashboard.tally-app.com/immaginiComuni/Cavalieri/Cavalieri-tavolo-Tally-menu-2.png" },
        { color: "white", url: "https://dashboard.tally-app.com/immaginiComuni/Cavalieri/Cavalieri-tavolo-Tally-menu-3-2.png" },
        { color: "black", url: "https://dashboard.tally-app.com/immaginiComuni/Cavalieri/Cavalieri-tavolo-Tally-menu-2-2.png" },
        { color: "white", url: "https://dashboard.tally-app.com/immaginiComuni/Cavalieri/Cavalieri-tavolo-Tally-menu-4-2.png" },
      ],
      errorLogin: false,
    };
    this.getTag = this.getTag.bind(this);
    this.getValueFromTag = this.getValueFromTag.bind(this);
    this.cambiaUrlQrCode = this.cambiaUrlQrCode.bind(this);
  }

  async componentDidMount() {
    document.title = "Prodotti - Tally Dashboard";
    Promise.all([this.getTag(), this.getValueFromTag(), common.getAzienda(this)]).then(() => { this.setState({ loaded: true }); });
  }
  async cambiaUrlQrCode(idTag) {
    await this.setState({ loadingQR: true });
    await this.setState({ idTagSelected: idTag });
    await this.setState({ loadingQR: false });
    var canvas = document.getElementById('body-qr').getElementsByTagName('canvas')[0];
    var img = canvas.toDataURL("image/png");
    await this.setState({ imgSelected: img });
  }
  async getTag() {
    let result = await common.eseguiPostRequestWithAuthorization("/getTag", {});
    if (result === null || result === undefined || !result.tag) {
      let port = "";
      (window.location.port || window.location.port != 80 || window.location.port !== 443) ? port = ":" + window.location.port : port = "";
      window.location.replace(window.location.protocol + "//" + window.location.hostname + port);
    } else {
      this.setState({ tag: result.tag });
    }
  }
  async getValueFromTag() {
    let result = await common.eseguiPostRequestWithAuthorization("/getTotalValueFromTag", {});
    if (result === null || result === undefined || !result.value) {

    } else {
      this.setState({ totaleViews: result.value.totaleViews, totaleProdotti: result.value.totaleProdotti });
    }
  }

  salvaPDF = async (progetto) => {
    if (true) {
      await this.setState({ createQR: true });
      let doc = new jsPDF()
      doc.setFont('Helvetica');
      doc.setFontSize(9);
      doc.setTextColor(this.state.templatePDF[this.state.templateChoose].color)
      let element;
      let k = 0;
      let offset;
      let img;
      let numeroQR = this.state.id_qr;
      img = new Image();
      img.src = "https://dashboard.tally-app.com/immaginiComuni/Cavalieri/Cavalieri-tavolo-Tally-menu-1.png";
      doc.addImage(img, 'png', 220, 80, 300, 220, null, null, 90);
      for (let i = 0; i < numeroQR; i++) {
        doc.addPage();
        await this.setState({ id_qr: i + 1 });
        element = document.getElementById('body-qr').getElementsByTagName('canvas')[0];
        img = new Image();
        img.src = this.state.templatePDF[this.state.templateChoose].url;
        doc.addImage(img, 'png', 205, 80, 280, 200, null, null, 90);
        doc.addImage(element.toDataURL("image/png"), 'png', 95, 25, 35, 35, null, null, 180);
        doc.addImage(element.toDataURL("image/png"), 'png', 115, 60, 35, 35, null, null, 0);
        doc.text((i + 1).toString(), 19, 105, null, -90);
        doc.text((i + 1).toString(), 190, 50, null, 90);
        //Secondo QR
        doc.addImage(element.toDataURL("image/png"), 'png', 95, 158, 35, 35, null, null, 180);
        doc.addImage(element.toDataURL("image/png"), 'png', 116, 192, 35, 35, null, null, 0);
        doc.text((i + 1).toString(), 19, 238, null, -90);
        doc.text((i + 1).toString(), 190, 183, null, 90);
        k++;
      }
      doc.save("Tally-pdf-" + progetto);
      await this.setState({ createQR: false });
    }
  }

  async cambiaTag(next) {
    this.setState({ animated: "" });
    let offset = this.state.offset + 1;
    let animazione = "viewTag-page animated fadeIn fast"
    if (next === 0) {
      offset = offset - 2;
      animazione = "viewTag-page animated fadeIn fast"
    }
    let result = await common.eseguiPostRequestWithAuthorization("/getTagWhitOffset", { offset: offset });
    if (result === null || result === undefined || !result.tag) {
    } else {
      this.setState({ offset: offset, animated: animazione });
      if (result.tag.length === 0) {
        this.cambiaTag(0)
      }
      else {
        this.setState({ tag: result.tag, offset: offset, animated: animazione });
      }
    }
  }
  render() {
    if (this.state.loaded !== true || this.state.errorLogin) {
      return (
        <Loading errorLogin={this.state.errorLogin}></Loading>
      );
    }
    return (
      <React.Fragment>
        <NavBarRight />
        <div style={{ minHeight: "700px" }}>
          <div className="row ">
            <div className="col-10 offset-1" style={{ marginTop: "40px", borderBottom: "1px solid #FF00D8" }}>
              <div className="row">
                <div className="col-1" style={{ marginTop: "-5px" }}>
                  <b className="titolo-pagina" style={{ marginRight: "20%" }}>{this.props.t('Prodotti')}</b>
                </div>
                <div style={{ textAlign: "right", float: "right" }} className="col-8 offset-3">
                  <p>
                    {this.state.azienda && this.state.azienda.TAG_DISPONIBILI > 0 && <span><b>Tag Disponibili:</b> {this.state.azienda && common.numberWithCommas(this.state.azienda.TAG_DISPONIBILI)}</span>}
                    <b style={{ marginLeft: "6%" }}>{this.props.t('Progetti Attivi')}</b>{" "}
                    {this.state.totaleProdotti && common.numberWithCommas(this.state.totaleProdotti)}
                    <b style={{ marginLeft: "6%" }}>{this.props.t('Visite Totali')}:</b> {this.state.totaleViews ? common.numberWithCommas(this.state.totaleViews) : 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row " style={{ marginTop: "2%" }}>
              <div className="col-1 offset-2 span-center">
                <span className="span-grid" >{this.props.t('Progetti')}</span>
              </div>
              <div className="col-1 span-center">
                <span className="span-grid" >{this.props.t('Data')}</span>
              </div>
              <div className="col-1 span-center">
                <span className="span-grid" >{this.props.t('Superficie')}</span>
              </div>
              <div className="col-1 span-center">
                <span className="span-grid" >{this.props.t('Città')}</span>
              </div>
              <div className="col-1 span-center">
                <span className="span-grid" >{this.props.t('Tag')}</span>
              </div>
              <div className="col-1 span-center">
                <span className="span-grid">{this.props.t('TTR')}</span>
              </div>
            </div>
            <div className={this.state.animated}>
              {((!this.state.totaleProdotti || this.state.totaleProdotti?.length === 0) && this.state.offset === 0) && <Redirect to="/dashboard"></Redirect>}
              {this.state.tag.map((tag, indice) => {
                return <TagView cambiaUrlQrCode={this.cambiaUrlQrCode} datiTag={this.state.datiTag} key={indice} taginfo={tag}></TagView>
              })}
            </div>
            {(this.state.tag.length === 6 || this.state.offset !== 0) &&
              <div className="viewtag-pagination-button">
                <span style={this.state.offset !== 0 ? { marginRight: "25px" } : { marginRight: "25px", color: "white", cursor: "default" }} onClick={() => { this.cambiaTag(0) }}> {this.props.t('< Precedente')}</span>
                <span className="viewtag-numero-pagina">Pagina: {this.state.offset + 1}</span>
                {this.state.tag.length === 6 && <span style={{ marginLeft: "25px" }} onClick={() => { this.cambiaTag(1) }}> {this.props.t('Successiva >')} </span>}
              </div>}
          </div>
        </div>

        <div className="modal fade" id="exampleModalCenter" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{ border: "1px solid rgb(255, 0, 216)" }}>
              <div className="modal-header" style={{ border: "0" }}>
                <img data-dismiss="modal" style={{ cursor: "pointer" }} className="close" src={"https://dashboard.tally-app.com/assets/icon/closeX.gif"} />
              </div>
              <div className="modal-body">
                <div id="body-qr" style={{ textAlign: "center" }}>
                  {this.state.createQR && <Loading errorLogin={this.state.errorLogin} />}
                  <div className="row" style={{ padding: "30px" }}>
                    <div className="col-6" style={{ textAlign: "left" }}>
                      <br></br>
                      <p><span className="tally-color-text font-weight-bold">Scegli se :</span> <br></br> - Scaricare un immagine contenente il <b>QRCode specifico del numero</b>, cliccando su Download Immagine.<br></br> - Scarica un <b>PDF con uno stile grafico selezionabile</b> qui sotto, cliccando su Download PDF.</p>
                      <p><b>Link condivisibile</b> <br></br><a href={"https://" + this.state.link + ".tally-tap.com/" + (this.state.azienda && this.state.azienda.SLANG_AZIENDA) + "/" + this.state.idTagSelected + "/1"}>
                        {"https://" + this.state.link + ".tally-tap.com/" + (this.state.azienda && this.state.azienda.SLANG_AZIENDA) + "/" + this.state.idTagSelected + "/1"}</a></p>
                      <p style={{ fontWeight: "bold", marginBottom: "2px" }}>Cambia il nome nel link</p>
                      <input type="text" value={this.state.link} onChange={(e) => { this.setState({ link: e.target.value }) }}></input>
                      <br></br>
                      <br></br>
                      <b>Numero QRCode</b><br></br> <span style={{ fontSize: "0.8em" }}>(specifico per stili grafici ristorazione)</span>
                      <br></br>
                      <input type="number" value={this.state.id_qr} onChange={(e) => { this.setState({ id_qr: e.target.value }); this.cambiaUrlQrCode(this.state.idTagSelected); }} placeholder="1"></input>
                      <br></br>
                      <br></br>
                      <b>Scegli lo stile grafico del PDF</b>
                      <br></br>
                      <button onClick={() => { this.setState({ templateChoose: 0 }) }} className={this.state.templateChoose == 0 ? "btn-radius-choose-template-active" : "btn-radius-choose-template"}>1</button>
                      <button onClick={() => { this.setState({ templateChoose: 1 }) }} className={this.state.templateChoose == 1 ? "btn-radius-choose-template-active" : "btn-radius-choose-template"}>2</button>
                      <button onClick={() => { this.setState({ templateChoose: 2 }) }} className={this.state.templateChoose == 2 ? "btn-radius-choose-template-active" : "btn-radius-choose-template"}>3</button>
                      <button onClick={() => { this.setState({ templateChoose: 3 }) }} className={this.state.templateChoose == 3 ? "btn-radius-choose-template-active" : "btn-radius-choose-template"}>4</button>
                    </div>
                    <div className="col-6">
                      <b>Codice QR con identificativo {this.state.id_qr}</b>
                      <br></br>
                      <div style={{ maxWidth: "100%" }}>
                        {!this.state.loadingQR && <QRCode size={256} includeMargin={true} value={"https://x.tally-tap.com/" + (this.state.azienda && this.state.azienda.SLANG_AZIENDA) + "/" + this.state.idTagSelected + "/" + this.state.id_qr} />}
                      </div>
                      <div style={{ margin: "0 auto", width: "70%" }}>
                        {!this.state.loadingQR &&
                          <div>
                            <a href={this.state.imgSelected} download={"QRcode-" + this.state.idTagSelected + "-" + this.state.id_qr} >
                              <button style={{ width: "100%" }} className="btn-tally-general-green">Download Immagine</button>
                            </a>
                            <div style={{ marginTop: "15px" }} />
                            <a href={this.state.imgSelected} onClick={() => { this.salvaPDF(this.state.idTagSelected) }} >
                              <button disabled={this.state.createQR} style={{ width: "100%" }} className="btn-tally-general-green">Download PDF</button>
                            </a>
                            <div style={{ textAlign: "left", marginTop: "15px" }}>
                              <span style={{ fontSize: "0.8em" }}>Cliccando su "Download PDF", verranno scaricati tanti QRCode quanto il numero assegnato.</span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
                <br></br>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );

  }
}
export default withTranslation()(ViewTag);
