import React from "react";
import InputDatiFatturazione from "../Component/inputDatiFatturazione.js";
import Autocomplete from 'react-google-autocomplete';

function DatiFatturazione(props) {
    return (
        <div>
            <div className="row choose-plan-row" >
                <div className="col-12 col-sm-4">
                    <span className="scegli-piano-title  ">2. Inserisci i dati di fatturazione </span><br></br>
                    <p className="descrizione-scegli-piano">Questi dati ci serviranno per<br></br> emettere la fattura mensilmente.</p>
                </div>
                <div className="col-12 col-sm-6 " >
                    <InputDatiFatturazione cambiaValoreDati={props.cambiaValoreDati} title="Partita IVA / CF" placeholder="Inserisci il numero di partita iva o il codice fiscale" nomeStato="partita_iva"></InputDatiFatturazione>
                    <InputDatiFatturazione cambiaValoreDati={props.cambiaValoreDati} title={<span><span className="scegli-piano-dati-fatturazione-span">Codice Univoco </span><span className="scegli-piano-dati-fatturazione-span-light">(fatturazione elettronica)</span></span>} nomeStato="codice_univoco_fatturazione" placeholder="Inserisci il codice univoco di fatturazione"></InputDatiFatturazione>
                    <p className="scegli-piano-dati-fatturazione-span">Sede Fiscale
                    {(!props.errorAddress[1] || !props.errorAddress[0]) &&
                            <span className="span-privacy-dati-fatturazione" style={{ color: "red" }}> *</span>
                        }
                    </p>
                    <div className="div-autocomplete-pagamento">
                        <Autocomplete
                            disabled={false}
                            className="scegli-piano-dati-fatturazione-div-input scegli-piano-dati-fatturazione-div-input-autocomplete "
                            onPlaceSelected={(place) => {
                                if (place) {
                                    props.setPlace(place);
                                }
                            }}
                            types={[]}
                        />
                    </div>
                    <br></br>
                    <InputDatiFatturazione cambiaValoreDati={props.cambiaValoreDati} title={<span><span className="scegli-piano-dati-fatturazione-span">Numero civico </span></span>} nomeStato="numero_civico" placeholder="Inserisci il numero civico"></InputDatiFatturazione>
                    {(!props.errorAddress[0] && props.errorAddress[1]) &&
                        <div className="div-span-privacy-dati-fatturazione" style={{ margin: "0" }}>
                            <p className="span-privacy-dati-fatturazione" style={{ color: "red", marginBottom: "0", marginTop: "4px" }}>Inserire numero civico corretto.</p>
                        </div>
                    }

                    {!props.errorAddress[1] &&
                        <div className="div-span-privacy-dati-fatturazione" style={{ margin: "0" }}>
                            <p className="span-privacy-dati-fatturazione" style={{ color: "red", marginBottom: "0", marginTop: "4px" }}>Inserire una via corretta.</p>
                        </div>
                    }
                    
                    <button onClick={() => { props.cambiaStato(2, null) }} className="button-scegli-piano button-scegli-piano-dati-fatturazione ">Avanti</button>
                    {props.messaggioError &&
                        <div className="div-span-privacy-dati-fatturazione">
                            <p className="span-privacy-dati-fatturazione" style={{ color: "red" }}>Per proseguire è necessario compilare tutti i campi.</p>
                        </div>
                    }

                    <div className="div-span-privacy-dati-fatturazione">
                        <p className="span-privacy-dati-fatturazione">Proseguendo dichiaro di aver letto e accettato le <a href="#" className="span-privacy-dati-fatturazione-active">Condizioni Generali di Fornitura del Servizio</a> i <a href="#" className="span-privacy-dati-fatturazione-active">Termini e condizioni</a> e <a href="#" className="span-privacy-dati-fatturazione-active">Politica sulla privacy</a> di Tally.</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DatiFatturazione;