import React from "react";
import common from "../Component/common.js";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
import Footer from "../Component/Footer.js";
import Header from "../Component/Header.js";
import InputTemplate from "../Component/InputTemplate.js";
import Loading from "../Component/Loading.js";
import Autocomplete from 'react-google-autocomplete';
import InfoBtn from "../Component/InfoBtn.js";
import NavBarRight from "../Component/NavBarRight.js";

class AddTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errore: false,
      errorLogin: false,
      checked: false,
      loaded: false,
      citta: "Citta",
      urlIcona: "Nothing",
      urlEtichetta: "Nothing",
      nazione: "Nazione",
      continente: "Continente",
      citta: "Citta",
      nextStep: false,
      completaCampi: false,
      azienda: {},
      tipologia_ordine: 1,
      civico: "",
      nome_prodotto: "",
      localita: "",
      idTag: false,
      check_input: [false, false, false, false, false, false, false, false, false, false, false],
      check_input_vuoti: [false, false, false, false, false, false, false, false, false, false, false],
    };
    this.cambiaValore = this.cambiaValore.bind(this);
    this.onChangeFileUpload = this.onChangeFileUpload.bind(this);
    this.inviaOrdine = this.inviaOrdine.bind(this);
    this.controllaCampi = this.controllaCampi.bind(this);
    this.controllaCampiVuoti = this.controllaCampiVuoti.bind(this);
    this.checkCampiBackend = this.checkCampiBackend.bind(this);
  }

  async componentDidMount() {
    common.getAzienda(this);
    document.title = "Crea Tag - Tally Dashboard";
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 200);

  }
  async controllaCampiVuoti(isQrcode) {
    let check = [false, false, false, false, false, false, false, false, false, false];
    if (this.state.nome_prodotto.length <= 0) {
      check[0] = true;
    }
    if (this.state.numero_tag === null && !isQrcode) {
      check[1] = true;
    }
    if (this.state.indirizzo_spedizione === null && !isQrcode) {
      check[2] = true;
    }
    if (this.state.civico.length === 0 && !isQrcode) {
      check[3] = true;
    }
    if (this.state.urlIcona === "Nothing") {
      check[5] = true;
    }
    if (this.state.punto_vendita === null) {
      check[6] = true;
    }
    if (!this.state.localita) {
      check[7] = true;
    }
    if (!this.state.diametro && !isQrcode && this.state.checked) {
      check[8] = true;
    }
    if ((!this.state.altezza || !this.state.larghezza) && !isQrcode && this.state.checked) {
      check[9] = true;
    }
    await this.setState({ check_input_vuoti: check });
  }
  async controllaCampi(isQrcode) {
    let check = [false, false, false, false, false, false, false, false, false, false, false];
    if (this.state.nome_prodotto.length > 25) {
      check[0] = true;
    }

    if (this.state.numero_tag < 1000 && !isQrcode) {
      check[1] = true;
    }

    if (this.state.indirizzo_spedizione === null && !isQrcode) {
      check[2] = false;
    }

    if (this.state.civico.length > 5 && !isQrcode) {
      check[3] = true;
    }

    let pattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');

    if (!pattern.test(this.state.urlIcona)) {
      check[5] = true;
    }

    if (this.state.punto_vendita > 25) {
      check[6] = true;
    }

    if (!this.state.localita) {
      check[7] = true;
    }

    if (this.state.diametro > 1000 && !isQrcode && this.state.checked) {
      check[8] = true;
    }

    if (this.state.altezza > 1000 || this.state.larghezza > 1000 && !isQrcode && this.state.checked) {
      check[9] = true;
    }
    await this.setState({ check_input: check });
  }
  checkCampiBackend() {
    for (let i = 0; i < this.state.check_input_vuoti.length - 3; i++) {
      if (this.state.check_input_vuoti[i] || this.state.check_input[i]) {
        return false;
      }
    }
    if (this.state.checked) {
      if ((this.state.check_input_vuoti[8] || this.state.check_input[8]) || (this.state.check_input_vuoti[9] || this.state.check_input[9]) || (this.state.check_input_vuoti[10] || this.state.check_input[10])) {
        return false;
      }
    }
    return true;
  }
  resetValoriQRCode = () => {
    this.setState({
      numero_tag: 0,
      nazione: {},
      indirizzo_spedizione: {},
      civico: 0,
      campanello: "",
      larghezza: 0,
      altezza: 0,
      diametro: 0,
      checked: false,
      urlEtichetta: "Nothing",
    })
  }

  async inviaOrdine() {

    await this.controllaCampiVuoti(this.state.tipologia_ordine === 1 ? true : false);
    await this.controllaCampi(this.state.tipologia_ordine === 1 ? true : false);
    if (this.checkCampiBackend()) {

      let parameters = {
        nome: this.state.nome_prodotto,
        numeroTag: this.state.numero_tag,
        puntoVendita: this.state.punto_vendita,
        iconaProdotto: this.state.urlIcona,
        iconaEtichetta: this.state.urlEtichetta,
        continente: this.state.continente,
        nazione: this.state.nazione,
        citta: this.state.citta,
        accoppiamento: this.state.checked,
        diametro: this.state.diametro,
        altezza: this.state.altezza,
        larghezza: this.state.larghezza,
        civico: this.state.civico,
        campanello: this.state.campanello,
        indirizzo_spedizione: this.state.indirizzo_spedizione,
        tipologia_ordine: this.state.tipologia_ordine
      };

      let result = await common.eseguiPostRequestWithAuthorization(
        "/effettuaOrdine",
        parameters
      );

      if (result && result.status === "success" && result.id) {

        this.setState({
          idTag: result.id,
          nextStep: true, errorCodeOrder: null
        });
      } else if (result.status === "error" && result.code) {
        this.setState({ errorCodeOrder: result.code });
      }
    }
  }
  cambiaValore(event) {
    common.cambiaValoreTesto(this, event);
  }
  cambiaChecked = () => {
    this.setState({
      checked: !this.state.checked
    });
  };
  onChangeFileUpload(event) {
    if (event.target.name === "file1") {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      this.setState({
        selectedFile: file,
        loadingImage: true
      });
      this.setState(() => ({ file }));
      const data = new FormData();
      data.append("file", file);
      if (file.size > 600000) {
        alert("Dimensione troppo grande (<500Kb), caricare un altra immagine");
        this.setState({
          loadingImage: false
        });
        return;
      }
      fetch(process.env.REACT_APP_BACKEND + "/uploadImageProfilo", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          Authorization: localStorage.getItem("token"),
          credentials: "include"
        },
        body: data
      })
        .then(res => res.json())
        .then(
          result => {
            if (result && result.imgpath) {
              this.setState({ loadingImage: false, urlIcona: result.imgpath, errorUploadImg: false });
            }
            else if (result.status === "error") {
              this.setState({ loadingImage: false, errorUploadImg: result.message })
            }
          },
          error => {
            alert("ERRORE");
          }
        );
    }
    else if (event.target.name === "file2") {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      this.setState({
        selectedFile: file,
        loadingImage: true
      });
      this.setState(() => ({ file }));
      const data = new FormData();
      data.append("file", file);
      if (file.size > 600000) {
        alert("Dimensione troppo grande (<500Kb), caricare un altro PDF");
        this.setState({
          loadingImage: false
        });
        return;
      }
      fetch(process.env.REACT_APP_BACKEND + "/UploadEtichetta", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          Authorization: localStorage.getItem("token"),
          credentials: "include"
        },
        body: data
      })
        .then(res => res.json())
        .then(
          result => {
            if (result && result.imgpath) {
              this.setState({ loadingImage: false, urlEtichetta: result.imgpath, errorUploadEtichetta: false });
            }
            else if (result && result.status === "error") {
              this.setState({ loadingImage: false, errorUploadEtichetta: result.message });
            }
          },
          error => {
            alert("ERRORE");
          }
        );
    }
  }
  render() {
    if (this.state.loaded !== true || this.state.errorLogin) {
      return (
        <Loading error={this.state.errorLogin}></Loading>
      );
    }
    return (
      <React.Fragment>
        <NavBarRight />
        <div className="row" style={{ marginTop: "40px" }}>
          <div className="col-12 offset-1">
            <span className="titolo-pagina">Nuovo Progetto</span><span style={{ fontSize: "1.2em", color: "#BEBEBE", fontWeight: "300", textDecoration: "none" }}> &nbsp;&gt;&nbsp; Scegli lo stile grafico &nbsp;&gt;&nbsp; Modifica i contenuti</span>
          </div>
        </div>
        {/*Step uno -> Selezione della modalità di applicazione*/}
        <div className="row page-tally animated fadeIn fast" style={{ marginTop: "40px" }}>
          <div className="col-2 offset-1">
            <p className="titolo-sezione-createtag"><span>1.</span> Seleziona la modalità</p>
            <div className="row">
              <div className="col-6" onClick={() => { this.resetValoriQRCode(); this.setState({ tipologia_ordine: 1 }) }} style={{ padding: "7px", height: "80px" }}>
                <div className={this.state.tipologia_ordine === 1 ? "box-tally-createtag-active d-flex justify-content-center" : "box-tally-createtag d-flex justify-content-center"}>
                  <div className="align-self-center">
                    <p><b>QR Code</b><br></br>+ Link</p>
                  </div>
                </div>
              </div>
              <div className="col-6" onClick={() => { this.setState({ tipologia_ordine: 0 }) }} style={{ padding: "7px", height: "80px" }}>
                <div className={this.state.tipologia_ordine === 0 ? "box-tally-createtag-active d-flex justify-content-center" : "box-tally-createtag d-flex justify-content-center"}>
                  <div className="align-self-center">
                    <p><b>NFC</b><br></br>+ QR Code</p>
                  </div>
                </div>
              </div>
            </div>
            <p className="selezione-create-tag">{this.state.tipologia_ordine === 0 ? <span><b>NFC</b> + QR Code</span> : <span><b>QR Code</b> + Link</span>}</p>
            <p className="title-input-create-tag">1. Seleziona la modalità</p>
            {this.state.tipologia_ordine === 1 && <p className="description-create-tag">Seleziona questa modalità se vuoi associare un QR Code ed un link al tuo progetto.</p>}
            {this.state.tipologia_ordine === 0 && <p className="description-create-tag">Seleziona questa modalità se vuoi associare la tecnologia NFC (verrà rilasciato anche un QR Code).</p>}
            <p className="title-input-create-tag">2. Inserisci i dati del progetto</p>
            <p className="description-create-tag">Inserisci i dati del progetto nella colonna sulla destra e premi sul pulsante verde “Avanti” per continuare.</p>
            <p className="title-input-create-tag">3. Crea la Web App</p>
            <p className="description-create-tag">Dopo aver compilato i dati e premuto su “Avanti”, apparirà una nuova colonna sulla destra, clicca sul bottone verde “Passa al Design.</p>
          </div>
          {/*Step due -> Inserisci i dati del progetto*/}
          <div className="col-4 offset-1">
            <p className="titolo-sezione-createtag"><span>2.</span> Inserisci i dati del progetto</p>
            <p className="selezione-create-tag">{this.state.tipologia_ordine === 0 ? <span><b>NFC</b> + QR Code</span> : <span><b>QR Code</b> + Link</span>}</p>
            <p className="title-input" style={{ marginTop: "20px", marginBottom: "0px" }}>Nome del Progetto {this.state.check_input_vuoti[0] && <span className="check-error">*</span>}</p>
            <input maxLength="25" style={{ width: "100%" }} onChange={this.cambiaValore} disabled={this.state.nextStep} name="nome_prodotto" />
            <br></br>{this.state.check_input[0] && <span className="check-error">Il Nome Progetto non deve superare i 25 caratteri.</span>}
            {/*NFC SPEDIZIONE*/}
            {(this.state.tipologia_ordine === 0 || this.state.tipologia_ordine === 2) &&
              <div>
                {/*Numero dei Tag*/}
                <p className="title-input" style={{ marginBottom: "0px", marginTop: "20px" }}>Numero dei Tag {this.state.check_input_vuoti[1] && <span className="check-error">*</span>}</p>
                <input type="number" min="1000" max="100000" style={{ width: "100%" }} onChange={this.cambiaValore} disabled={this.state.nextStep} name="numero_tag" />
                <br></br>{this.state.check_input[1] && <span className="check-error">Il Numero Tag deve essere superiore a 1000.</span>}
                {/*Indirizzo di spedizione*/}
                <p style={{ marginTop: "20px" }} className="titolo-tally" >
                  Indirizzo di spedizione
                </p>
                <p className="descrizione-tally margin-inside-box" style={{ textDecoration: "underline", marginLeft: "10px" }}>
                  &bull; Indirizzo di spedizione dei Tag {this.state.check_input_vuoti[2] && <span className="check-error">*</span>}
                </p>
                <Autocomplete
                  disabled={this.state.nextStep}
                  className="margin-inside-box"
                  style={{ width: '98%', marginBottom: "10px" }}
                  onPlaceSelected={(place) => {
                    if (place) {
                      this.setState({ indirizzo_spedizione: place });
                    }
                  }}
                  types={[]}
                />
                {this.state.check_input[2] && <span className="check-error">error</span>}
                {/*Numero Civico*/}
                <p className="descrizione-tally margin-inside-box" style={{ textDecoration: "underline" }}> &bull; Numero Civico {" "}{this.state.check_input_vuoti[3] && <span className="check-error">*</span>}</p>
                <input type="string" className="margin-inside-box" style={{ width: "98%" }} onChange={this.cambiaValore} disabled={this.state.nextStep} name="civico" />
                <br></br>
                {this.state.check_input[3] && <span className="check-error">Numero Civico può contere al massimo 5 cifre.</span>}
                {/*Note*/}
                <p className="descrizione-tally margin-inside-box" style={{ marginTop: "10px", textDecoration: "underline" }}>
                  &bull; Note* {this.state.check_input_vuoti[4] && <span className="check-error">*</span>}</p>
                <input type="string" placeholder="" className="margin-inside-box" style={{ width: "98%" }} onChange={this.cambiaValore} disabled={this.state.nextStep} name="campanello" />
                <br></br>{this.state.check_input[4] && <span className="check-error">Le Note non devono superare i 40 caratteri.</span>}
              </div>
            }
            {/*Icona Prodotto*/}
            <p className="titolo-tally" style={{ marginTop: "20px", width: "100%" }}>
              Icona Progetto <InfoBtn info="Caricare un immagine quadrata con risoluzione massima 800 x 800px"></InfoBtn> {this.state.check_input_vuoti[5] && <span className="check-error">*</span>}
            </p>
            {this.state.errorUploadImg && <span className="check-error">{this.state.errorUploadImg}</span>}
            {this.state.check_input[5] && <span className="check-error">Icona Progetto deve essere un link.</span>}
            {this.state.urlIcona !== "Nothing" && (
              <div>
                <p className="descrizione-tally" style={{ color: "#818181" }}>
                  Anteprima:
                </p>
                <div style={{ width: "100px", height: "100px", overflow: "hidden" }}>
                  <img
                    style={{ height: "100%", objectFit: "cover" }}
                    src={this.state.urlIcona}
                  ></img>
                </div>
              </div>
            )}

            <label style={{ display: "flex", alignItems: "center", cursor: "pointer", border: "1px solid #BEBEBE", borderRadius: "20px", textAlign: "center", width: "100%", marginTop: "5px", color: "#BEBEBE", lineHeight: "0.9em" }}
              className={this.state.loadingImage || this.state.nextStep ? "disabled-image-input" : ""}
            >
              <span style={{ marginTop: "5px", marginBottom: "5px", margin: "0 auto", color: "black" }}> {this.state.urlIcona === "Nothing" && "Carica Icona"}
                {this.state.urlIcona !== "Nothing" && "Cambia immagine"}</span>
              <input
                style={{ visibility: "hidden" }}
                type="file"
                name="file1"
                onChange={this.onChangeFileUpload}
                disabled={this.state.loadingImage || this.state.nextStep}
              />
            </label>
            {/*Citta di destinazione*/}
            <p className="titolo-tally" style={{ marginTop: "20px" }}>
              Associa la città <InfoBtn info="Inserisci la città"></InfoBtn>{this.state.check_input_vuoti[7] && <span className="check-error">*</span>}
            </p>
            {this.state.loaded && <Autocomplete
              id="addressText"
              disabled={this.state.nextStep}
              style={{ width: '100%', marginTop: "-3px", marginBottom: "20px" }}
              placeholder=""
              onPlaceSelected={(place) => {
                if (place && place.address_components) {
                  this.setState({ continente: place });
                  place.address_components.forEach(component => {
                    if (component.types.includes('country')) {
                      this.setState({ nazione: component.long_name })
                    }
                    if (component.types.includes('administrative_area_level_3')) {
                      this.setState({ citta: component.long_name })
                    }
                  });
                  this.setState({ localita: place });
                }
              }}
              types={['(cities)']}
            />}
            {/*Punto Vendita*/}
            <p className="titolo-tally">
              Indirizzo / Punto vendita  <InfoBtn info="Inserisci un punto vendita o il tuo stesso negozio" />{this.state.check_input_vuoti[6] && <span className="check-error">*</span>}
              {this.state.completaCampi && (
                <span className="titolo-tally" style={{ color: "red" }}>
                  *
                </span>
              )}
            </p>
            <input maxLength="30" style={{ width: "100%", marginTop: "-3px" }} onChange={this.cambiaValore} disabled={this.state.nextStep} name="punto_vendita" />
            <br></br>
            {this.state.check_input[6] && <span className="check-error">Il Punto Vendita deve essere inferiore ai 25 caratteri.</span>}
            <br></br>
            {/*Accoppiamento etichetta*/}
            {(!this.state.checked && (this.state.tipologia_ordine === 0 || this.state.tipologia_ordine === 2)) && <p className="titolo-tally">Accoppiamento etichetta <InfoBtn info="Scegli questa opzione se vuoi che ci occupiamo noi di accoopiare la tua etichetta con i chip NFC"></InfoBtn></p>}
            {(this.state.checked && (this.state.tipologia_ordine === 0 || this.state.tipologia_ordine === 2)) &&
              <div className="animated fade-in">
                {/*Upload Etichetta*/}
                <p className="titolo-tally" style={{ width: "100%" }}>
                  Etichetta <InfoBtn info="Inserisci la tua etichetta in formato .PDF o .AI"></InfoBtn> {this.state.check_input_vuoti[10] && <span className="check-error">*</span>}
                </p>
                {this.state.errorUploadEtichetta && <span className="check-error">{this.state.errorUploadEtichetta}</span>}
                {this.state.check_input[10] && <span className="check-error">L'Etichetta deve essere un file PDF.</span>}
                {this.state.urlEtichetta !== "Nothing" && (
                  <div>
                    <p className="descrizione-tally" style={{ color: "#10d300", marginBottom: "8px" }}>Etichetta caricata correttamente! clicca <a href={this.state.urlEtichetta} >qui</a> per visualizzarla</p>
                  </div>
                )}
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "1px solid rgb(190, 190, 190)",
                    borderRadius: "20px",
                    textAlign: "center",
                    width: "100%",
                    color: "#707070",
                    lineHeight: "0.9em"
                  }}
                  className={this.state.loadingImage || this.state.nextStep ? "disabled-image-input" : ""}
                >
                  <span style={{ marginTop: "5px", marginBottom: "5px", margin: "0 auto", color: "black" }}> {this.state.urlEtichetta === "Nothing" && "Carica Etichetta"}
                    {this.state.urlEtichetta !== "Nothing" && "Cambia Etichetta"}</span>

                  <input
                    style={{ visibility: "hidden" }}
                    type="file"
                    name="file2"
                    onChange={this.onChangeFileUpload}
                    disabled={this.state.loadingImage || this.state.nextStep}
                  />
                </label>


                {/*Diamentro Bobina*/}
                <p className="titolo-tally" style={{ marginBottom: "0", marginTop: "20px" }}>Diametro bobina {this.state.check_input_vuoti[8] && <span className="check-error">*</span>}</p>
                <input type="number" placeholder="Diametro" style={{ width: "30%", marginTop: "0px" }} onChange={this.cambiaValore} disabled={this.state.nextStep} name="diametro" />
                <div style={{ display: "inline-block", width: "10%" }}>mm</div>
                {this.state.check_input[8] && <span className="check-error">Il Diametro non può essere superiore ai 1000 mm.</span>}
                <p className="titolo-tally" style={{ marginTop: "20px" }}>Dimensione etichetta {this.state.check_input_vuoti[9] && <span className="check-error">*</span>} </p>
                {/*Larghezza*/}
                <input type="number" placeholder="Larghezza" style={{ width: "30%", marginTop: "0px" }} onChange={this.cambiaValore} disabled={this.state.nextStep} name="larghezza" />
                <div style={{ display: "inline-block", width: "10%" }}>mm</div>
                {/*Altezza*/}
                <input type="number" placeholder="Altezza" style={{ width: "30%", marginTop: "0px" }} onChange={this.cambiaValore} disabled={this.state.nextStep} name="altezza" />
                <div style={{ display: "inline-block", width: "10%" }}>mm</div>
                <br></br>{this.state.check_input[9] && <span className="check-error">Altezza e Larghezza non possono essere superiori ai 1000 mm.</span>}
              </div>}
            {(this.state.tipologia_ordine === 0 || this.state.tipologia_ordine === 2) &&
              <div className="switchToggle">
                <input
                  type="checkbox"
                  id="switch1"
                  checked={this.state.checked}
                  onChange={this.cambiaChecked}
                  disabled={this.state.nextStep}
                />
                <label htmlFor="switch1">Toggle</label>
              </div>
            }
            <button
              style={{ marginTop: "10px" }}
              className="button-success-tally"
              disabled={this.state.nextStep}
              onClick={this.inviaOrdine}
            >
              Avanti
            </button>
            {this.state.errorCodeOrder && <span className="check-error">{this.state.errorCodeOrder}</span>}
          </div>

          {/*Ultimo Step*/}
          {this.state.nextStep && (
            <div
              className="col-2 offset-1"
              style={{ textAlign: "center" }}
            >
              <p className="titolo-sezione-createtag"><span>3.</span> Crea la tua Web App</p>
              <br></br>
              <img
                src={"https://dashboard.tally-app.com/assets/icon/smile-tally.svg"}
                style={{ width: "50%" }}
              />{" "}
              <br></br>
              <br></br>
              <p style={{ fontSize: "32px", lineHeight: "0.9em", fontWeight: "400" }}>
                &Egrave; l'ora di <br />
                creare
              </p>
              <p style={{ fontWeight: "300", lineHeight: "1.2em" }}>
                Il tuo progetto è stato creato <br></br>con successo!
              </p>
              <Link
                to={`/choose-template/${this.state.idTag}`}
                disabled={!this.state.idTag}
              >
                <button disabled={!this.state.idTag} className="button-success-tally">
                  Passa al design
                </button>
              </Link>
              <br></br>
              <img
                style={{ marginTop: "4%" }}
                src={"https://dashboard.tally-app.com/assets/icon/freccia-tally.svg"}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default AddTag;
