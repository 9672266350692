import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";
import "../Style/Header.css";
import common from "./common.js";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

export default (props) => {
  const [errorLogin, setErrorLogin] = useState(0);
  const [language, setLanguage] = useState(i18n.language);
  const [azienda, setAzienda] = useState(null);
  const [sectionActive, setSectionActive] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    checkLogin();
    setSectionActive(window.location.pathname);

  }, []);
  const checkLogin = async () => {
    let result = await common.eseguiPostRequestWithAuthorization(
      "/checkLogin",
      {}
    );
    if (result == false || result === null || result === undefined || !result.azienda) {
      window.location.href = '/login';
      return false;
    }

    else if (result.status == "success" && result.azienda[0].PAGATO == 1) {
      localStorage.setItem("azienda", JSON.stringify(result.azienda[0]));
      setAzienda(result.azienda[0]);
      return true;
    }
    else if (result.azienda[0].PAGATO == 0) {
      localStorage.setItem("azienda", JSON.stringify(result.azienda[0]));
      window.location.href = '/pagamento';
    }
  }
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("rememberme");
    localStorage.removeItem("azienda");
    window.location.href = '/login';
  }

  const changeLanguage = (e) => {
    const lng = e.target.value
    setLanguage(lng)
    i18n.changeLanguage(lng);
  }
  return (
    <header>
      <div className="row" >
        <div className="col-4 offset-1" style={{ marginTop: "12px" }}>
          <div className="row">
            <div className="col-2">
              <Link to="/order-tag"><img src={azienda && azienda.URL_LOGO} className="myimg" /></Link>
            </div>
            <div className="col-8 informazioni-header">
              <span style={{ fontWeight: "bold" }}>{azienda && azienda.NOME_AZIENDA}</span>
              <br></br>
              <span>
                <Link to="/order-tag"><i><span className="link-header" style={{ fontWeight: "300", color: "black", fontSize: "0.8em", textDecoration: "underline" }} >{t('Profilo')}</span></i></Link>
                <span style={{ fontWeight: "300", fontSize: "0.8em" }}> /</span>
                <i><span className="link-header" style={{ fontWeight: "300", fontSize: "0.8em", cursor: "pointer", textDecoration: "underline" }} onClick={logout}>Log-out</span></i>
              </span>
            </div>
          </div>
        </div>
        <div className="col-6" style={{ marginTop: "12px", textAlign: "right" }}>
          <Link to="/dashboard"><button onClick={() => setSectionActive("/dashboard")} style={{ width: "15%" }} className={sectionActive === "/dashboard" ? "btn-tally-general-active" : "btn-tally-general"}>{t('Home')}</button></Link>

          <Link to="/view-tag"><button onClick={() => setSectionActive("/view-tag")} style={{ width: "15%" }} className={sectionActive === "/view-tag" ? "btn-tally-general-active" : "btn-tally-general"}>{t('Progetti')}</button></Link>

          <Link to="/support"><button onClick={() => setSectionActive("/support")} style={{ width: "15%" }} className={sectionActive === "/support" ? "btn-tally-general-active" : "btn-tally-general"}>{t('Supporto')}</button></Link>

          <Link to="/create-order"><button onClick={() => setSectionActive("/create-order")} style={{ marginRight: "0", width: "25%" }} className={sectionActive === "/create-order" ? "btn-tally-general-green-active" : "btn-tally-general-green"}>{t('Nuovo Progetto')}
          </button></Link>

          {true && <select value={language} onChange={changeLanguage}>
            <option value="it">it</option>
            <option value="es">es</option>
            <option value="en">en</option>
          </select>}
        </div>
      </div>
    </header>
  );
}
