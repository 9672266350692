import React from "react";
import common from "../Component/common.js";
import Loading from "../Component/Loading.js";
import ActiveIcon from "../Component/activeIcon.js";
import "../Style/Login.css";
import InputTemplate from "../Component/InputTemplate.js";
import Collapse from "../Component/Collapse.js";
import NewPassword from "../Component/newPassword.js";
import CambiaImmagineProfilo from "../Component/OrderTag/CambiaImmagineProfilo.js";
import NavBarRight from "../Component/NavBarRight.js";

import swal from 'sweetalert';

class OrderTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            tag: [],
            errorLogin: false,
            errorNuovaPassword: false,
            check_change_password: false,
            tagView: [],
            tagOrderSelected: false,
            inputTag: "",
            errorNumeroTag: false,
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        };
    }
    cancellaAccount = async () => {
        const result = await common.eseguiPostRequestWithAuthorization("/deleteAccount");
        if (result.status == "success") {
            swal("In 15 giorni cancelleremo tutti i dati della sua azienda dai nostri database.");
        }
        else {
            swal("Oops!", "Cancellazione dati non andata a buon fine, contattare assistenza!", "error");
        }
    }

    annullaAbbonamento = async () => {
        const result = await common.eseguiPostRequestWithAuthorization("/annullaAbbonamento");
        if (result.status == "success") {
            alert("Il suo abbonamento è stato annullato.");
        }
        else {
            alert("Contattare l'assistenza, abbonamento non annullato.");
        }
    }

    inviaNuovaPassword = async () => {
        if (this.state.newPassword && this.state.confirmNewPassword && this.state.newPassword === this.state.confirmNewPassword) {
            this.setState({ loader: true });
            let result = await common.eseguiPostRequestWithAuthorization(
                "/inviaNuovaPassword",
                {
                    nuovaPassword: this.state.newPassword,
                    confermaNuovaPassword: this.state.confirmNewPassword,
                    vecchiaPassword: this.state.oldPassword

                });
            if (result.status === "success") {
                this.setState({ check_change_password: true });
            }
            if (result.status === "error") {
                this.setState({ errorNuovaPassword: true });
            }
        }
        else {
            this.setState({ errorNuovaPassword: true })
        }
    }

    cambiaValoreAzienda = (e) => {
        let azienda_edit = this.state.azienda_edit;
        azienda_edit = { ...azienda_edit, [e.target.name]: e.target.value };
        this.setState({ azienda_edit: azienda_edit });
    }

    changeValuePassword = async (e) => {
        if (e.target.name === "Password Attuale") {
            await this.setState({ oldPassword: e.target.value })
        }
        else if (e.target.name === "Nuova Password") {
            await this.setState({ newPassword: e.target.value })
        }
        else if (e.target.name === "Conferma Nuova Password") {
            await this.setState({ confirmNewPassword: e.target.value })
        }
    }

    changeValueInput = async (e) => {
        let numeroTag = e.target.value;
        if (numeroTag >= 1000 && typeof numeroTag) {
            await this.setState({ inputTag: numeroTag, errorNumeroTag: false });
        }
        else {
            await this.setState({ inputTag: null });
        }

    }
    async componentDidMount() {
        document.title = "Profilo - Tally Dashboard";
        await this.getTag();
        common.getAzienda(this);
        this.setState({
            azienda_edit:
            {
                nome_azienda: this.state.azienda.NOME_AZIENDA,
                partita_iva: this.state.azienda.PARTITA_IVA_AZIENDA,
                codice_univoco_fatturazione: this.state.azienda.COD_UNIVOCO_FATTURAZIONE,
                via: this.state.azienda.VIA,
                cap: this.state.azienda.CAP,
                pec: this.state.azienda.PEC,
                nazione: this.state.azienda.NAZIONE
            }
        });

        this.setState({ loaded: true });
    }
    getTag = async () => {
        let result = await common.eseguiPostRequestWithAuthorization("/getTotalValueFromTag", {});
        if (result === null || result === undefined) {
            return;
        } else if (result.status === "success") {
            this.setState({ totaleViews: result.value.totaleViews, totaleProdotti: result.value.totaleProdotti });
        }
    }

    render() {
        if (this.state.loaded !== true || this.state.errorLogin) {
            return (
                <Loading errorLogin={this.state.errorLogin}></Loading>
            );
        }
        return (
            <div>
                <NavBarRight />
                <div className="row" style={{ marginTop: "40px" }}>
                </div>
                <div>
                    <div className="col-3 offset-1">
                        <p className="titolo-pagina">Profilo</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 offset-1">
                        <div className="box-tally">
                            <p></p>
                            <p className="title-input">Progetti attuali <br></br><span style={{ textDecoration: "none", fontWeight: "400" }}>{(this.state.totaleProdotti && common.numberWithCommas(this.state.totaleProdotti))}/{this.state.azienda && this.state.azienda.PRODOTTI_DISPONIBILI}</span></p>
                            <p></p>
                            <p></p>
                            <p className="title-input">Data inizio contratto <br></br>
                                <span style={{ fontWeight: "400" }}>{new Date(this.state.azienda.DATA_INIZIO_CONTRATTO).toLocaleDateString()}</span>
                            </p>
                            <p></p>
                            <p></p>
                            <p className="title-input">Data rinnovo contratto <br></br>
                                <span style={{ fontWeight: "400" }}>{new Date(this.state.azienda?.DATA_SCADENZA_CONTRATTO).toLocaleDateString()}</span>
                            </p>
                            <p></p>
                            <p></p>
                            <p className="title-input">Stato abbonamento<br></br>
                                <span style={{ textDecoration: "none", fontWeight: "400" }}>

                                    {this.state.azienda?.PAGATO == 1 ? <ActiveIcon attivo={true} testo={"Attivo"} /> : <ActiveIcon attivo={false} testo={"Non attivo"} />}
                                </span>
                            </p>
                            <p></p>
                            <p></p>
                            <p className="title-input">Tag disponibili <br></br><span style={{ textDecoration: "none", fontWeight: "400" }}>{this.state.azienda && common.numberWithCommas(this.state.azienda.TAG_DISPONIBILI)}</span></p>
                            <p></p>
                            <p></p>
                            <p className="title-input">Views Attuali <br></br><span style={{ textDecoration: "none", fontWeight: "400" }}>{this.state.totaleViews ? common.numberWithCommas(this.state.totaleViews) : 0}/{this.state.azienda && common.numberWithCommas(this.state.azienda.LIMITE_VIEW)}</span></p>
                            <p></p>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="row">
                            <div className="col-3">
                                <div className="box-tally" id="box-tally-reset" style={{ paddingTop: "15px", paddingBottom: "10px" }}>
                                    <Collapse id="newPassword" nome="Cambia Password" contenuto={
                                        <div>
                                            <NewPassword disabled={this.state.check_change_password} changeValuePassword={this.changeValuePassword} id="password-attuale" nome="Password Attuale" placeholder="Password Attuale" /><br></br>
                                            <NewPassword disabled={this.state.check_change_password} changeValuePassword={this.changeValuePassword} id="nuova-password" nome="Nuova Password" placeholder="Nuova Password" /><br></br>
                                            <NewPassword disabled={this.state.check_change_password} changeValuePassword={this.changeValuePassword} id="nuova-password" nome="Conferma Nuova Password" placeholder="Conferma Nuova Password" />
                                            <button onClick={this.inviaNuovaPassword} style={{ marginTop: "15px" }} className="button-success-tally">Procedi</button>
                                            {
                                                this.state.check_change_password && <span style={{ color: "green", fontSize: "0.9em" }}>Password modificata.</span>
                                            }
                                            {
                                                this.state.errorNuovaPassword && !this.state.check_change_password && <span style={{ color: "red", fontSize: "0.9em" }}>Errore nell'inserimento.</span>
                                            }
                                        </div>
                                    } />

                                </div>
                                <br></br>
                                <div className="box-tally" style={{ paddingTop: "15px", paddingBottom: "10px", overflowX: "hidden" }}>
                                    {(this.state.azienda && this.state.azienda.URL_LOGO) &&
                                        <CambiaImmagineProfilo imgAttuale={this.state.azienda && this.state.azienda.URL_LOGO}></CambiaImmagineProfilo>}
                                </div>
                            </div>
                            <div className="col-4">
                                <InputTemplate
                                    titoloHeader="Nome azienda"
                                    cambia={this.cambiaValoreAzienda}
                                    name={"nome_azienda"}
                                    type="text"
                                    maxlength="40"
                                    titolo={this.state.azienda_edit?.nome_azienda}
                                />
                                <InputTemplate
                                    titoloHeader="Partita Iva"
                                    cambia={this.cambiaValoreAzienda}
                                    name={"partita_iva"}
                                    type="text"
                                    maxlength="40"
                                    titolo={this.state.azienda_edit.partita_iva}
                                />
                                <InputTemplate
                                    titoloHeader="Codice univoco fatturazione"
                                    cambia={this.cambiaValoreAzienda}
                                    name={"codice_univoco_fatturazione"}
                                    type="text"
                                    maxlength="40"
                                    titolo={this.state.azienda_edit.codice_univoco_fatturazione}
                                />
                                <InputTemplate
                                    titoloHeader="Via"
                                    cambia={this.cambiaValoreAzienda}
                                    name={"via"}
                                    type="text"
                                    maxlength="40"
                                    titolo={this.state.azienda_edit.via}
                                />
                                <InputTemplate
                                    titoloHeader="PEC"
                                    cambia={this.cambiaValoreAzienda}
                                    name={"pec"}
                                    type="text"
                                    maxlength="40"
                                    titolo={this.state.azienda_edit.pec}
                                />
                                <InputTemplate
                                    titoloHeader="CAP"
                                    cambia={this.cambiaValoreAzienda}
                                    name={"cap"}
                                    type="text"
                                    maxlength="40"
                                    titolo={this.state.azienda_edit.cap}
                                />
                                <InputTemplate
                                    titoloHeader="Nazione"
                                    cambia={this.cambiaValoreAzienda}
                                    name={"nazione"}
                                    type="text"
                                    maxlength="40"
                                    titolo={this.state.azienda_edit.nazione}
                                />
                                <button className="btn-tally-supporto">Aggiorna Dati</button>
                            </div>
                            <div className="col-3">
                                <p style={{ fontWeight: "bold", lineHeight: "1.2em" }}>Annulla abbonamento Tally<br />
                                    <span style={{ fontWeight: "300", fontSize: "0.9em" }}>(Il tuo account verrà disabilitato al termine del contratto mensile, potrai comunque accedere e rinnovare l'abbonamento)</span></p>
                                <button onClick={async () => {
                                    let choose = await swal({
                                        title: "Sei sicuro di voler annullare il pagemento?",
                                        text: "Verrai rindirizzato alla pagina di pagamenti automatici di Paypal, da li potrai annullare il tuo pagamento. L'account Tally rimmarà attivo fino alla fine del mese pagato!",
                                        icon: "warning",
                                        buttons: ["Annulla", "Vai a PayPal"],

                                    });
                                    if (choose) window.location.href = 'https://www.paypal.com/myaccount/autopay/';
                                }} className="button-danger-tally">Annulla</button>
                                <br></br>
                                <br></br>
                                <p style={{ fontWeight: "bold", lineHeight: "1.2em" }}>Cancella account Tally <br />
                                    <span style={{ fontWeight: "300", fontSize: "0.9em" }}>(in 15 giorni tutti i tuoi dati verranno persi definitivamente)</span>
                                </p>
                                <button onClick={async () => {
                                    let choose = await swal({
                                        title: "Sei sicuro?",
                                        text: "Stai per cancellare tutti i dati inseriti all'interno di Tally",
                                        icon: "warning",
                                        buttons: ["Annulla", "Si"],

                                    });
                                    if (choose) this.cancellaAccount();
                                }} className="button-alert-tally">Cancella account</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                    </div>
                </div>
            </div >
        );
    }
}
export default OrderTag;
