import React from "react";
import PayPalPage from "../Component/payPalPage.js";
import ChoosePlan from "../Component/choosePlan.js";
import DatiFatturazione from "../Component/datiFatturazione.js";
import common from "../Component/common.js";
import { Redirect } from "react-router-dom";
import Loading from "../Component/Loading.js";

class Pagamento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stato_registrazione: 0,
            aggiorna_abbonamento: false,
            piano_scelto: 0,
            check_dati: [false, false, false, false, false],
            messaggio_error: false,
            errorAddress: [true, true],
            loaded: false
        };

        this.cambiaStato = this.cambiaStato.bind(this);
        this.cambiaValoreDati = this.cambiaValoreDati.bind(this);
        this.checkDati = this.checkDati.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.checkDatiAzienda = this.checkDatiAzienda.bind(this);
    }
    logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("rememberme");
        localStorage.removeItem("azienda");
        window.location.href = '/login';
    }
    async componentDidMount() {
        let azienda = localStorage.getItem("azienda");
        if (azienda && JSON.parse(azienda) && JSON.parse(azienda).PAGATO == 1) {
            window.location.href = '/';
        }
        await this.checkDatiAzienda();
        document.title = "Pagamento - Tally Dashboard";
        this.setState({ loaded: true });
    }

    checkSubscription = (result) => {
        if (result == "success") {
            this.insertDati();
        }
    }

    insertDati = async () => {
        if (!this.state.aggiorna_abbonamento) {
            let parameters = this.state.show_dati_fatturazione ? {
                partita_iva: this.state.partita_iva,
                codice_univoco_fatturazione: this.state.codice_univoco_fatturazione,
                sede_fiscale: this.state.sede_fiscale,
                cap: this.state.cap,
                nazione: this.state.nazione,
                fattura: true
            } : { fattura: false }

            let result = await common.eseguiPostRequestWithAuthorization(
                "/insertDatiFatturazione",
                parameters
            );
            if (result) {
                window.location.href = '/';
            }
        }
    }

    async checkDatiAzienda() {
        let result = await common.eseguiPostRequestWithAuthorization("/checkAbbonamento", {});
        if (result.status === "error") {
            this.setState({ aggiorna_abbonamento: false });
            return false;
        }
        else (result.status === "success") ?
            this.setState({ ...result.abbonamenti, aggiorna_abbonamento: true }) : this.setState({ errorLogin: true });
        this.setState({ loaded: true });
    }

    async cambiaStato(stato, piano) {
        if (stato == 2 && await this.checkAll()) {
            this.setState({ stato_registrazione: stato })
        }
        else if (stato == 2) {
            let check = await this.checkAll();
            if (!check) {
                this.setState({ messaggio_error: true })
            }
        }
        else if (stato == 1) {
            (this.state.aggiorna_abbonamento == true) ?
                this.setState({ stato_registrazione: 2 }) :
                this.setState({ stato_registrazione: stato })
        }
        if (piano) {
            this.setState({ piano_scelto: piano })
        }
    }

    setPlace = (place) => {
        if (!place.hasOwnProperty('address_components')) {
            this.setState({ errorAddress: [true, true] });
            return;
        }
        let count_correct = 0;
        let nazione;
        let cap;
        let check = false;
        place.address_components.forEach((oggettoAddress, index) => {
            if (oggettoAddress.types.includes("route")) {
                check = true;
                count_correct++;
            }
            else if (oggettoAddress.types.includes("postal_code")) {
                cap = oggettoAddress.long_name;
                count_correct++;
            }
            else if (oggettoAddress.types.includes("country")) {
                nazione = oggettoAddress.long_name;
                count_correct++;
            }

        });

        if (count_correct >= 3 && check) {
            this.setState({ sede_fiscale: place.formatted_address, cap: cap, nazione: nazione })
        }
        else if (!check) {
        }

    }
    async cambiaValoreDati(event) {
        await this.setState({ [event.target.name]: event.target.value });
    }

    async checkDati() {
        let partitaIva = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        let check = [false, false, false, false, false];
        if (!this.state.partita_iva || this.state.partita_iva.length <= 0 || partitaIva.test(this.state.partita_iva)) {
            check[0] = true;
        }
        if (!this.state.codice_univoco_fatturazione || this.state.codice_univoco_fatturazione.length <= 0) {
            check[1] = true;
        }
        if (!this.state.sede_fiscale || this.state.sede_fiscale.length <= 0) {
            check[2] = true;
        }
        if (!this.state.cap || this.state.cap.length <= 0) {
            check[3] = true;
        }
        if (!this.state.nazione || this.state.nazione.length <= 0) {
            check[4] = true;
        }
        await this.setState({ check_dati: check });
    }

    async checkAll() {
        await this.checkDati();
        for (let i = 0; i < this.state.check_dati.length; i++) {
            if (this.state.check_dati[i]) {
                return false;
            }
        }
        return true;
    }

    render() {
        if (this.state.errorLogin) {
            return (<Redirect to="/login"></Redirect>)
        }
        else if (!this.state.loaded) {
            return <Loading></Loading>
        }
        else {
            return (
                <div >
                    <div className="div-pagamento">
                        <div className="div-pagamento-content">
                            <p>
                                <span className=
                                    {this.state.stato_registrazione == 0 ? "numero-piano-active" : "numero-piano"}>1/</span>

                                {!this.state.aggiorna_abbonamento &&
                                    <span className={this.state.stato_registrazione == 1 ? "numero-piano-active" : "numero-piano"}>2/</span>
                                }

                                {this.state.aggiorna_abbonamento &&
                                    <span className={this.state.stato_registrazione == 2 ? "numero-piano-active" : "numero-piano"}>2/</span>
                                }

                                {!this.state.aggiorna_abbonamento &&
                                    <span className={this.state.stato_registrazione == 2 ? "numero-piano-active" : "numero-piano"}>3</span>
                                }
                                {
                                    {
                                        0: <span className="scegli-piano-span">Scegli il tuo piano!</span>,
                                        1: <span className="scegli-piano-span">Dati di fatturazione</span>,
                                        2: <span className="scegli-piano-span">Procedi con il pagamento</span>
                                    }[this.state.stato_registrazione]
                                }
                            </p>
                            {
                                {
                                    0: <ChoosePlan cambiaStato={this.cambiaStato} />,
                                    1: <div>
                                        {!this.state.show_dati_fatturazione &&

                                            <div>
                                                <p>Vuoi richiedere la fattura?</p>
                                                <button onClick={() => { this.setState({ show_dati_fatturazione: true }) }} style={{ width: "100px" }} className="btn-tally-general-green">Si</button>
                                                <button onClick={() => { this.setState({ stato_registrazione: 2 }) }} style={{ width: "100px" }} className="btn-tally-grey">No</button>
                                            </div>}
                                        {this.state.show_dati_fatturazione &&
                                            <DatiFatturazione errorAddress={this.state.errorAddress} setPlace={this.setPlace} messaggioError={this.state.messaggio_error} cambiaValoreDati={this.cambiaValoreDati} cambiaStato={this.cambiaStato} />
                                        }
                                    </div>
                                    ,
                                    2: this.state.aggiorna_abbonamento
                                        ? <PayPalPage checkSubscription={this.checkSubscription} pianoScelto={this.state.piano_scelto} numero="2" />
                                        : <PayPalPage checkSubscription={this.checkSubscription} pianoScelto={this.state.piano_scelto} numero="3" />
                                }[this.state.stato_registrazione]
                            }
                        </div>
                        <div>
                            <p style={{ fontSize: "0.9em" }}>Esegui il <a href="#" style={{ fontSize: "1em" }} className="link-tally" onClick={this.logout}>logout</a> e torna alla pagina di login.</p>
                        </div>
                    </div>
                </div >
            );
        }
    }
}

export default Pagamento;