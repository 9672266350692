import React from "react";
import common from "../Component/common.js";
import Loading from "../Component/Loading.js";
import SupportForm from "../Component/SupportForm.js";
import SupportTicket from "../Component/SupportTicket.js";
import Collapse from "../Component/Collapse.js";
import ChatModal from "../Component/ChatModal.js";
import SendMessage from "../Component/SendMessage.js";
import ReceivedMessage from "../Component/ReceivedMessage.js";
import NavBarRight from "../Component/NavBarRight.js";
import ReactPlayer from 'react-player'

class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowCount: 1,
            loaded: false,
            errorLogin: false,
            richieste: [],
            messaggi: [],
            sendMessageShow: [],
            receivedMessageShow: [],
            textareaMessage: '',
            idSelected: 0,
            addRichiesta: false
        };
        this.getRichieste = this.getRichieste.bind(this);
        this.getMessaggio = this.getMessaggio.bind(this);
        this.createMessage = this.createMessage.bind(this);
        this.cambiaValoreMessaggio = this.cambiaValoreMessaggio.bind(this);
        this.aggiungiRichiesta = this.aggiungiRichiesta.bind(this);
    }
    async componentDidMount() {
        document.title = "Supporto - Tally Dashboard";
        await common.getAzienda(this);
        await this.getRichieste();
        this.setState({ loaded: true });
    }

    aggiungiRichiesta() {
        this.setState({ addRichiesta: !this.state.addRichiesta });
    }
    async getRichieste() {
        let result = await common.eseguiPostRequestWithAuthorization("/getRichieste", {});
        if (result === null || result === undefined || !result.richieste) {
            this.setState({ nothingRequest: true })
        } else {
            this.setState({ richieste: result.richieste, richiestaSelezionata: result.richieste[0] });
            this.getMessaggio(result.richieste[0].ID_RICHIESTA)
        }
        this.setState({ addRichiesta: false });

    }
    async createMessage() {
        if (this.state.textareaMessage.length < 10) { return false; }
        let res = await common.eseguiPostRequestWithAuthorization("/createMessage", { id_richiesta: this.state.idRichiestaSelezionata, testo: this.state.textareaMessage });
        this.setState({ textareaMessage: "" });
        this.getMessaggio(this.state.idRichiestaSelezionata);
    }
    async getMessaggio(id) {
        this.setState({ idRichiestaSelezionata: id });
        //Chiamo backend e prendo il messaggio della richiesta
        this.state.richieste.forEach(richiesta => {
            if (richiesta.ID_RICHIESTA === id) {
                this.setState({ richiestaSelezionata: richiesta });
            }
        });
        let result = await common.eseguiPostRequestWithAuthorization("/getMessaggio", { id_richiesta: id });
        if (result === null || result === undefined) {
            this.setState({ errorLogin: true })
        } else {
            this.setState({ messaggi: result.messaggi });
        }
    }
    cambiaValoreMessaggio(e) {
        let count = this.state.rowCount;
        if (e.target.value.length < this.state.textareaMessage.length && this.state.textareaMessage[this.state.textareaMessage.length - 1] === "\n") {
            count--;
        }
        if ((e.target.value.length > this.state.textareaMessage.length && e.target.value.charAt(e.target.value.length - 1) === "\n")) {
            count++
        }
        this.setState({ rowCount: count, textareaMessage: e.target.value });
    }
    render() {
        if (this.state.loaded !== true || this.state.errorLogin) {
            return (
                <Loading errorLogin={this.state.errorLogin}></Loading>
            );
        }
        return (
            <React.Fragment>
                <div className="animated fadeIn fast">
                    <div className="row" className="titolo-pagina-div titolo-pagina-div-support ">
                        <div className="col-3 offset-1">
                            <p className="titolo-pagina">Supporto</p>
                        </div>
                        <NavBarRight />
                    </div>
                    <div style={{ marginTop: "40px" }} className="row content-supporto">
                        <div className="col-3 offset-1 div-ticket-support">
                            <p className="titolo-alto-ticket-support"><img style={{ width: "25px" }} src="https://dashboard.tally-app.com/immaginiComuni/mail.svg" /> &nbsp;Ticket</p>
                            {!this.state.addRichiesta &&
                                <div>
                                    <div className="scroll-support">
                                        {this.state.richieste.map((richiesta, indice) => {
                                            return (<SupportTicket key={"supportTiken-" + indice} activeLi={this.activeLi} idSelected={this.state.idRichiestaSelezionata} getMessaggio={this.getMessaggio} richiesta={richiesta}></SupportTicket>)
                                        })}
                                    </div>
                                    <button className="btn-tally-supporto" onClick={this.aggiungiRichiesta}>Nuovo Ticket</button>
                                </div>
                            }
                            {this.state.addRichiesta &&
                                <div>
                                    <img onClick={this.aggiungiRichiesta} className="close close-support-form" src={"https://dashboard.tally-app.com/assets/icon/closeX.gif"} />
                                    <SupportForm getRichieste={this.getRichieste}></SupportForm>
                                </div>
                            }
                        </div>
                        <div className="col-3 div-ticket-support-2">
                            <div className="scroll-support-2">
                                <p className="collapse-titolo-alto-ticket-support" ><img style={{ width: "25px" }} src="https://dashboard.tally-app.com/assets/icon/question.svg" /> &nbsp;FAQs</p>
                                <Collapse id="content1" nome="Posso modificare un prodotto una volta inserito?" contenuto="Certamente, una volta inseriti i prodotti sulla piattaforma potrai modificarli in tempo reale così che 
i tuoi clienti siano sempre informati sulle novità. Potrai anche rendere visibile o meno un prodotto 
nel caso tu abbia finito le disponibilità."></Collapse>
                                <Collapse id="content2" nome="Posso modificare un prodotto una volta inserito?" contenuto="Gli ordini arriveranno sul numero di cellulare che inserirai all’interno dei tuoi progetti. Potrai 
modificarlo ogni volta che vuoi."></Collapse>
                                <Collapse id="content3" nome="Come fanno i miei clienti per pagare i prodotti?" contenuto="Una volta che il cliente ha scelto i prodotti da acquistare ti invierà un messaggio su Whatsapp 
dove potrai concordare di persona il metodo di pagamento."></Collapse>
                            </div>
                        </div>
                        <div className="col-4 " style={{ paddingRight: "4%" }} >
                            <div className="div-ticket-support-3" style={{ paddingLeft: "5%" }}>
                                <div>
                                    <p className="collapse-titolo-alto-ticket-support"><img style={{ width: "25px" }} src="https://dashboard.tally-app.com/assets/icon/video.svg" />  &nbsp;Video Tutorial</p>
                                    <Collapse id="VT-content1" nome="Registrazione al portale" contenuto={
                                        <div style={{ maxWidth: "100%" }}>
                                            <ReactPlayer height={"250px"} controls style={{ maxWidth: "100%", height: "150px" }} url='https://youtu.be/4YPCbjLSwM4'></ReactPlayer>
                                        </div>
                                    } />
                                    <Collapse id="VT-content2" nome="Creazione nuovo progetto" contenuto={
                                        <div style={{ maxWidth: "100%" }}>
                                            <ReactPlayer height={"250px"} controls style={{ maxWidth: "100%", height: "150px" }} url='https://youtu.be/JVBEVVUXYvo'></ReactPlayer>
                                        </div>
                                    } />
                                    <Collapse id="VT-content3" nome="Scelta stile grafico e apertura negozio" contenuto={
                                        <div style={{ maxWidth: "100%" }}>
                                            <ReactPlayer height={"250px"} controls style={{ maxWidth: "100%", height: "150px" }} url='https://youtu.be/2V5vLaUpmuk'></ReactPlayer>
                                        </div>
                                    } />
                                    <Collapse id="VT-content4" nome="Inserimento categorie e prodotti" contenuto={
                                        <div style={{ maxWidth: "100%" }}>
                                            <ReactPlayer height={"250px"} controls style={{ maxWidth: "100%", height: "150px" }} url='https://youtu.be/MzbUNE0m9n8'></ReactPlayer>
                                        </div>
                                    } />
                                    <Collapse id="VT-content5" nome="Condivisione e risultato finale" contenuto={
                                        <div style={{ maxWidth: "100%" }}>
                                            <ReactPlayer height={"250px"} controls style={{ maxWidth: "100%", height: "150px" }} url='https://youtu.be/yHzfQm451xk'></ReactPlayer>
                                        </div>
                                    } />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChatModal nomeazienda={this.state.azienda && this.state.azienda.NOME_AZIENDA} richiesta={this.state.richiestaSelezionata} cambiaValoreMessaggio={this.cambiaValoreMessaggio} textareaMessage={this.state.textareaMessage} createMessage={this.createMessage} messaggi={this.state.messaggi}></ChatModal>
            </React.Fragment>
        );
    }
}
export default Support;
