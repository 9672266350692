import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { hotjar } from 'react-hotjar';


import Login from "./Page/Login.js";
import createTag from "./Page/createTag.js";
import ViewTag from "./Page/ViewTag.js";
import ChooseTemplate from "./Page/ChooseTemplate.js";
import ManageTemplate from "./Page/ManageTemplate.js";
import OrderTag from "./Page/OrderTag.js";
import Tag from "./Page/Tag.js";
import Dashboard from "./Page/Dashboard.js"
import ResetPassword from "./Page/ResetPassword.js";
import Support from "./Page/Support.js";
import SignUp from "./Page/SignUp.js";
import ManageCreateTemplate from "./Component/manageCreateTemplate.js";
import Pagamento from "./Page/Pagamento.js";
import Header from "./Component/Header.js";
import Footer from "./Component/Footer.js";
import './i18n';
import Orders from "./Page/Orders.js";

function App(props) {
  hotjar.initialize(1749821, 6);
  const exclusionArray = [
    '/login',
    '/reset-password',
    '/sign-up',
    '/pagamento',
    '/test'
  ]
  return (
    <div className="tally-dashboard">
      <Router>
        {exclusionArray.indexOf(window.location.pathname) < 0 && <Header />}
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/login/" component={Login} />
        <Route exact path="/reset-password/" component={ResetPassword} />
        <Route exact path="/pagamento/" component={Pagamento} />
        <Route exact path="/dashboard/" component={Dashboard} />
        <Route exact path="/view-tag/" component={ViewTag} />
        <Route exact path="/tag/:idTag" component={Tag} />
        <Route exact path="/order-tag/" component={OrderTag} />
        <Route exact path="/orders/" component={Orders} />
        <Route exact path="/create-order/" component={createTag} />
        <Route exact path="/support/" component={Support} />
        <Route exact path="/sign-up/" component={SignUp} />
        <Route exact path="/choose-template/:idTag" component={ChooseTemplate} />
        <Route exact path="/create-template/:idTag" component={ManageTemplate} />
        <Route exact path="/create-template/:idTag/:idTemplate" component={ManageCreateTemplate} />
        {exclusionArray.indexOf(window.location.pathname) < 0 && <Footer />}
      </Router>
      <div className="mobile-tally-dashboard">
      </div>
    </div>
  );
}
export default App;
