import React from "react";

function InputDatiFatturazione(props) {
    return (
        <div className="scegli-piano-list dati-fatturazione-span-input">
        <p className="scegli-piano-dati-fatturazione-span">{props.title}</p>
        <div className="scegli-piano-dati-fatturazione-div-input">
        <input onChange={props.cambiaValoreDati} className="scegli-piano-dati-fatturazione-div-input" placeholder={props.placeholder} name={props.nomeStato} ></input>
        </div>
    </div>
    );
}

export default InputDatiFatturazione;